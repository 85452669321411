import { Spinner, Flex, Text } from '@chakra-ui/react';

const Loading: React.FC<{ message?: string; size?: string }> = ({ message, size = 'xl' }) => {
  return (
    <Flex width="100%" height="100%" alignItems="center" justifyContent="center" direction="column">
      {message != null && (
        <Text mb={4} fontSize="lg">
          {message}
        </Text>
      )}
      <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size={size} />
    </Flex>
  );
};

export default Loading;
