import { CheckIcon, CloseIcon, EditIcon } from '@chakra-ui/icons';
import { Flex, IconButton, Input, FormControl, Spacer, FormLabel, Text } from '@chakra-ui/react';
import { useState, useRef } from 'react';

interface EditableTextProps {
  label: string;
  value: string;
  onConfirmChange: (value: string) => void;
  isDisabled?: boolean;
}

const EditableText: React.FC<EditableTextProps> = ({ label, value, onConfirmChange, isDisabled = false }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editValue, setEditValue] = useState(value);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleEdit = (): void => {
    setIsEditing(true);
    setTimeout(() => inputRef.current?.focus(), 0);
  };

  const handleCancel = (): void => {
    setIsEditing(false);
    setEditValue(value);
  };

  const handleConfirm = (): void => {
    if (editValue.trim() !== value) {
      onConfirmChange(editValue.trim());
    }
    setIsEditing(false);
  };

  return (
    <FormControl display="flex" alignItems="center">
      <FormLabel mb="0" width="175px" fontSize="sm">
        {label}
      </FormLabel>
      <Flex flex={1} align="center">
        {isEditing ? (
          <>
            <Input
              ref={inputRef}
              value={editValue}
              onChange={(e) => {
                setEditValue(e.target.value);
              }}
              onKeyDown={(e) => {
                e.key === 'Enter' && handleConfirm();
              }}
              size="sm"
            />
            <Spacer />
            <IconButton ml="1" size="sm" icon={<CheckIcon />} onClick={handleConfirm} aria-label="confirm" />
            <IconButton ml="1" size="sm" icon={<CloseIcon />} onClick={handleCancel} aria-label="cancel" />
          </>
        ) : (
          <>
            <Text fontSize="sm">{value ?? '—'}</Text>
            <Spacer />
            {!isDisabled && <IconButton size="sm" icon={<EditIcon />} onClick={handleEdit} aria-label="edit" />}
          </>
        )}
      </Flex>
    </FormControl>
  );
};

export default EditableText;
