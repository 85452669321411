import { InfoIcon } from '@chakra-ui/icons';
import {
  Flex,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Text,
  Box,
  VStack,
  Heading,
  useToast,
  Divider,
  SimpleGrid
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import React from 'react';
import { useSelector } from 'react-redux';

import { Loading } from '@features/shared/components';
import { selectActiveOrgID } from '@features/user-settings/userSlice';
import { cadenceToFriendlyDisplay } from '@features/wallets/utils';
import { useGetProfileGroupByIdQuery } from '@services/canaria.services';

const ProfileGroupOverview: React.FC<{ profileGroupId: string }> = ({ profileGroupId }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const activeOrgID = useSelector(selectActiveOrgID);
  const toast = useToast();

  if (activeOrgID == null) {
    throw new Error('activeOrgID is null');
  }

  const { data: profileGroup, isLoading } = useGetProfileGroupByIdQuery({
    orgId: activeOrgID,
    profileGroupId
  });

  const formatDate = (dateString: string): string => {
    return dayjs(dateString).format('MMMM D, YYYY');
  };

  if (activeOrgID == null) {
    toast({
      title: 'Error',
      description: 'Active organization ID is not set.',
      status: 'error',
      duration: 9000,
      isClosable: true
    });
    return null;
  }

  if (isLoading) {
    return <Loading size="sm" />;
  }

  if (profileGroup == null) {
    return (
      <Box padding="6" boxShadow="lg" bg="white">
        <Text>No profile group information available. Please check your settings or try again later.</Text>
      </Box>
    );
  }

  const groupSchedulesByType = (schedules): any[] => {
    return schedules.reduce((acc, schedule) => {
      const { screeningType } = schedule;
      if (acc[screeningType] == null) {
        acc[screeningType] = [];
      }
      acc[screeningType].push(schedule);
      return acc;
    }, {});
  };

  const renderSchedules = (schedules): JSX.Element[] => {
    const grouped = groupSchedulesByType(schedules);
    return Object.keys(grouped).map((type, idx) => (
      <Box key={type} mt="4">
        <Heading size="sm" mb="2">
          {type} Schedules:
        </Heading>
        <SimpleGrid columns={2} spacingX="40px" spacingY="2">
          {grouped[type].map((schedule, index) => (
            <React.Fragment key={index}>
              <Text fontWeight="bold">Risk: {schedule.riskRating}</Text>
              <Text>Frequency: {cadenceToFriendlyDisplay(schedule.frequency)}</Text>
            </React.Fragment>
          ))}
        </SimpleGrid>
        {idx < Object.keys(grouped).length - 1 && <Divider my="4" />}
      </Box>
    ));
  };

  return (
    <>
      <IconButton ml="1" icon={<InfoIcon />} aria-label="Interrogate" onClick={onOpen} size="sm" />
      <Modal isOpen={isOpen} onClose={onClose} size="2xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Profile Group Information</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4} align="stretch">
              <Heading size="md">{profileGroup.name}</Heading>
              <Flex justifyContent="space-between" fontStyle="italic" color="gray.500">
                <Text fontSize="xs">Created At: {formatDate(profileGroup.createdAt)}</Text>
                <Text fontSize="xs">Updated At: {formatDate(profileGroup.updatedAt)}</Text>
              </Flex>
              <Text>{profileGroup.description}</Text>
              <Text fontWeight="bold">Min Score for profile screening: {profileGroup.profileScreeningMinScore}</Text>
              {profileGroup.screeningSchedules != null && renderSchedules(profileGroup.screeningSchedules)}
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ProfileGroupOverview;
