import {
  Tag,
  Text,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
  Tfoot,
  Flex,
  Box,
  Grid,
  GridItem
} from '@chakra-ui/react';
import { Doughnut } from 'react-chartjs-2';
import { SlExclamation } from 'react-icons/sl';

import { CATEGORY_NAMES, RiskRank } from '@utils/consts';

interface IExposure {
  category: string;
  value: number;
  exposureType: 'direct' | 'indirect';
}
interface ExposureSectionProps {
  exposures: IExposure[];
  chartData: any;
  exposureRisk: any;
  sum: number;
}

const sortExposures = (exposures: IExposure[], exposureRisk: any): IExposure[] => {
  const clonedExposures = [...exposures];
  return clonedExposures.sort((a, b) => {
    const categoryA = exposureRisk.get(b.category);
    const categoryB = exposureRisk.get(a.category);
    if (categoryA === categoryB) {
      return b.value - a.value;
    }
    return RiskRank[categoryA] - RiskRank[categoryB];
  });
};

const ExposureSection: React.FC<ExposureSectionProps> = ({ exposures, chartData, exposureRisk, sum }) => {
  const sortedExposures = sortExposures(exposures, exposureRisk);
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  });

  if (exposures != null && exposures.length === 0) {
    return (
      <Grid templateColumns="auto" justifyItems={'center'} pt={4} pb={4}>
        <GridItem pb={4}>
          <SlExclamation size={30} />
        </GridItem>
        <GridItem>
          <Text>No exposure data available</Text>
        </GridItem>
      </Grid>
    );
  }

  return (
    <Flex flexWrap="wrap" gap={3}>
      <Box>
        <Doughnut data={chartData} />
      </Box>
      <TableContainer rounded="lg">
        <Table size="sm">
          <Thead>
            <Tr>
              <Th>Category</Th>
              <Th>Risk</Th>
              <Th>Type</Th>
              <Th isNumeric>Value</Th>
              <Th isNumeric>Percent (%)</Th>
            </Tr>
          </Thead>
          <Tbody>
            {sortedExposures.map(function (d, idx) {
              return (
                <Tr key={idx}>
                  <Td>{CATEGORY_NAMES[d.category] ?? d.category}</Td>
                  <Td>
                    <Tag bg={`risk.${exposureRisk.get(d.category).toUpperCase()}`} color="white">
                      {exposureRisk.get(d.category)}
                    </Tag>
                  </Td>
                  <Td>{d.exposureType}</Td>
                  <Td isNumeric>{formatter.format(d.value)}</Td>
                  <Td>{(d.value / sum) * 100 < 1 ? '<1' : ((d.value / sum) * 100).toFixed(2)}%</Td>
                </Tr>
              );
            })}
          </Tbody>
          <Tfoot>
            <Tr>
              <Th fontSize={'md'}>TOTAL</Th>
              <Th fontSize={'md'}></Th>
              <Th fontSize={'md'}></Th>
              <Th fontSize={'md'}>{formatter.format(sum)}</Th>
              <Th fontSize={'md'}>100%</Th>
            </Tr>
          </Tfoot>
        </Table>
      </TableContainer>
    </Flex>
  );
};

export default ExposureSection;
