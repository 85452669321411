export const extractAddressDetails = (
  place: google.maps.places.PlaceResult
): {
  city: string;
  state: string;
  country: string;
  postalCode: string;
  subLocality: string;
  neighborhood: string;
} => {
  let city = '';
  let state = '';
  let country = '';
  let postalCode = '';
  let subLocality = '';
  let neighborhood = '';

  place.address_components?.forEach((component) => {
    if (component.types.includes('neighborhood')) {
      neighborhood = component.long_name;
    }
    if (component.types.includes('sublocality_level_1')) {
      subLocality = component.long_name;
    }
    if (component.types.includes('locality')) {
      city = component.long_name;
    }
    if (component.types.includes('administrative_area_level_1')) {
      state = component.long_name;
    }
    if (component.types.includes('country')) {
      country = component.short_name;
    }
    if (component.types.includes('postal_code')) {
      postalCode = component.long_name;
    }
  });

  return { city, state, country, postalCode, subLocality, neighborhood };
};
