import { Text, Link, Select, Button, useToast } from '@chakra-ui/react';
import * as Sentry from '@sentry/browser';
import { useState } from 'react';

import { cadenceToFriendlyDisplay } from '@features/wallets/utils';

const CadenceDisplay: React.FC<{
  frequency: string;
  walletId: string;
  orgId: string;
  updateWallet?: any;
  updateWalletIsLoading?: boolean;
}> = ({ frequency, walletId, orgId, updateWallet, updateWalletIsLoading }) => {
  const [cadenceValue, setCadenceValue] = useState(frequency);
  const [isEditingCadence, setIsEditingCadence] = useState(false);
  const toast = useToast();

  const handleCadenceChange = (event): void => {
    setCadenceValue(event.target.value);
  };

  if (!isEditingCadence) {
    return (
      <>
        <Text size="sm" mb={0} fontWeight="bold">
          {cadenceToFriendlyDisplay(frequency)}
        </Text>
        {typeof updateWallet === 'function' && (
          <Link
            size="sm"
            pl={1}
            onClick={() => {
              setCadenceValue(frequency);
              setIsEditingCadence(true);
            }}
          >
            (edit)
          </Link>
        )}
      </>
    );
  }

  return (
    <>
      <Select size="sm" onChange={handleCadenceChange} maxWidth="10ch" defaultValue={frequency}>
        <option value="N">None</option>
        <option value="D">Daily</option>
        <option value="W">Weekly</option>
        <option value="M">Monthly</option>
      </Select>
      <Button
        size="xs"
        ml={2}
        mr={2}
        isDisabled={updateWalletIsLoading}
        bg="red"
        colorScheme="green"
        onClick={() => {
          setIsEditingCadence(false);
        }}
      >
        Cancel
      </Button>
      <Button
        size="xs"
        isLoading={updateWalletIsLoading}
        bg="green.400"
        colorScheme="green"
        onClick={async () => {
          try {
            await updateWallet({
              orgId,
              walletId,
              frequency: cadenceValue
            }).unwrap();
            setIsEditingCadence(false);
          } catch (err) {
            toast({
              status: 'error',
              title: 'Error',
              description: 'Oh no, there was an error!',
              isClosable: true
            });
            Sentry.captureException(err);
          }
        }}
      >
        Save
      </Button>
    </>
  );
};

export default CadenceDisplay;
