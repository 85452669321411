import { Box, Flex, Text, Link as ChakraLink, Button } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { getStatusText, truncateAddress } from '@features/panels/alertsPanel/utils/alertUtils';
import { selectUser } from '@features/user-settings/userSlice';

const AlertHeader: React.FC<{ alert: any; onReassign: () => void }> = ({ alert, onReassign }) => {
  const isWatchlist = alert.resourcetype === 'WatchlistAlert';
  const currentUser = useSelector(selectUser);

  return (
    <Box p={5} bg="white" outline="1px solid black" mb={4}>
      <Flex justifyContent="space-between">
        <Flex direction="column">
          <Text fontSize="xl" fontWeight={700}>
            {`Alert (ID: ${alert.id})`}
          </Text>
          <Text>Creation Date: {dayjs(alert?.created_at).format('YYYY-MM-DD hh:mm:ss A')}</Text>
          <Text>Last Modification: {dayjs(alert?.created_at).format('YYYY-MM-DD hh:mm:ss A')}</Text>
        </Flex>
        <Flex direction="column">
          <Text> Alert Type: {alert.resourcetype}</Text>
          <Text> Alert Status: {getStatusText(alert.status)}</Text>
          <Text>
            {isWatchlist ? 'Profile ID: ' : 'Wallet Address: '}
            <ChakraLink
              as={Link}
              to={`/dashboard/${isWatchlist ? 'profiles' : 'wallets'}/${alert.profile?.id != null ? alert.profile.id : alert.wallet?.id}`}
              color="blue.400"
              textDecoration="underline"
            >
              {alert.profile?.id != null ? alert.profile.id : truncateAddress(alert.wallet?.address)}
            </ChakraLink>
          </Text>
          {alert.reviewed_by != null && (
            <Flex alignItems="center">
              <Text mr={2}>
                Assigned to: {alert.reviewed_by.first_name} {alert.reviewed_by.last_name} ({alert.reviewed_by.email})
              </Text>
              {currentUser.email !== alert.reviewed_by.email && (
                <Button size="sm" onClick={onReassign} bg="black" color="white" _hover={{ bg: 'gray.800' }}>
                  Reassign to me
                </Button>
              )}
            </Flex>
          )}
        </Flex>
        {isWatchlist ? (
          <Flex direction="column">
            <Text> Total Hits: {alert.profile_inquiries?.length}</Text>
            <Text>
              Total False Positives:
              {alert.profile_inquiries?.filter((inquiry) => inquiry.resolution === 'FALSE_POSITIVE').length}
            </Text>
            <Text>
              Total True Matches:
              {alert.profile_inquiries?.filter((inquiry) => inquiry.resolution === 'TRUE_MATCH').length}
            </Text>
          </Flex>
        ) : (
          <Flex direction="column">
            <Text> Previous Wallet Exposure: {alert.prev_inquiry_result?.result?.risk}</Text>
            <Text> New Wallet Exposure: {alert.current_inquiry_result?.result?.risk}</Text>
          </Flex>
        )}
      </Flex>
    </Box>
  );
};

export default AlertHeader;
