import { createSlice, createAction } from '@reduxjs/toolkit';

import { loadLocalStorageState, saveLocalStorageState } from '../local-storage/local-storage';

const key = 'auth';
const localStorageState = loadLocalStorageState<{
  refresh: string;
  access: string;
}>(key);

const authLogoutAction = createAction('AUTH_LOGOUT');
const authLoggedInAction = createAction('AUTH_LOGGED_IN');

const initialState = {
  refresh: localStorageState?.refresh ?? '',
  access: localStorageState?.access ?? '',
  isLoggedIn: Boolean(localStorageState?.refresh)
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setTokens: (state, action) => {
      state.refresh = action.payload.refresh;
      state.access = action.payload.access;
      state.isLoggedIn = !(action.payload.refresh === '');

      saveLocalStorageState(key, {
        refresh: action.payload.refresh,
        access: action.payload.access
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase(authLogoutAction.type, (state, action) => {
      state.refresh = '';
      state.access = '';
      state.isLoggedIn = false;
    });
  }
});

export { authLogoutAction, authLoggedInAction };
export const { setTokens } = slice.actions;
export default slice.reducer;
export const selectIsLoggedIn = (state): boolean => state.auth.isLoggedIn;
