import { CloseIcon } from '@chakra-ui/icons';
import { type UseToastOptions } from '@chakra-ui/react';
import {
  Icon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  VStack,
  FormControl,
  Input,
  Textarea,
  Text,
  Box,
  Select,
  useToast
} from '@chakra-ui/react';
import { useRef, useState, useCallback } from 'react';
import { AiFillFilePdf, AiOutlineFile } from 'react-icons/ai';

import {
  useGetSignedUrlMutation,
  useGetProfileAttachmentTypesQuery,
  useUploadDocumentMutation
} from '@services/canaria.services';
import { uploadFileToSignedUrl } from '@services/utils';

interface AttachmentModalProps {
  isOpen: boolean;
  onClose: () => void;
  orgId: string;
  profileId: string;
  fixedAttachmentType?: string;
}

const toastNoFileSelected: UseToastOptions = {
  title: 'No file selected.',
  description: 'Please select a file before uploading.',
  status: 'error',
  duration: 5000,
  isClosable: true
};

const toastFileLoaded: UseToastOptions = {
  title: 'File loaded.',
  description: 'Your file has been successfully loaded.',
  status: 'success',
  duration: 5000,
  isClosable: true
};

const toastFileUploaded: UseToastOptions = {
  title: 'File uploaded.',
  description: 'Your file has been successfully uploaded.',
  status: 'success',
  duration: 5000,
  isClosable: true
};

const toastUploadFailed: UseToastOptions = {
  title: 'Upload failed.',
  description: 'There was an error uploading your file.',
  status: 'error',
  duration: 5000,
  isClosable: true
};

const AttachmentModal: React.FC<AttachmentModalProps> = ({
  isOpen,
  onClose,
  orgId,
  profileId,
  fixedAttachmentType
}) => {
  const toast = useToast();
  const { data: attachmentTypes } = useGetProfileAttachmentTypesQuery(null);

  const fileInputRef = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<File | null>(null);
  const [attachmentName, setAttachmentName] = useState<string>('');
  const [attachmentNotes, setAttachmentNotes] = useState<string>('');
  const [attachmentType, setAttachmentType] = useState<string>(fixedAttachmentType ?? '');
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [fileUploading, setFileUploading] = useState(false);

  const [uploadDocument, { isLoading }] = useUploadDocumentMutation();
  const [getSignedUrl] = useGetSignedUrlMutation();
  const handleFileDrop = (e: React.DragEvent<HTMLDivElement>): void => {
    e.preventDefault();
    updateFileAndPreview(e.dataTransfer.files[0]);
    toast(toastFileLoaded);
  };

  const handleSelectFilesClick = (): void => {
    if (fileInputRef.current != null) {
      fileInputRef.current.click();
    }
  };

  const handleClose = useCallback((): void => {
    if (previewUrl != null) {
      URL.revokeObjectURL(previewUrl);
      setPreviewUrl(null);
    }
    setFile(null);
    setAttachmentName('');
    onClose();
  }, [onClose, previewUrl]);

  const handleUpload = async (): Promise<void> => {
    if (file == null) {
      toast(toastNoFileSelected);
      return;
    }

    try {
      const signedUrlResponse = await getSignedUrl({
        orgId,
        profileId,
        filename: file.name,
        mimeType: file.type,
        fileSize: file.size
      }).unwrap();
      setFileUploading(true);
      if (file != null) {
        await uploadFileToSignedUrl(signedUrlResponse.signedUrl, file);
        await uploadDocument({
          orgId,
          profileId,
          key: signedUrlResponse.key,
          attachmentType,
          name: attachmentName,
          notes: attachmentNotes
        }).unwrap();
        toast(toastFileUploaded);
      }
    } catch (error: any) {
      if (error.data?.error != null) {
        toast({
          title: 'Upload failed.',
          description: error.data.error,
          status: 'error',
          duration: 5000,
          isClosable: true
        });
      } else {
        toast(toastUploadFailed);
      }
    } finally {
      handleClose();
      setFileUploading(false);
      setFile(null);
      setAttachmentType('');
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const files = e.target.files;
    if (files != null && files.length > 0) {
      updateFileAndPreview(files[0]);
      toast({
        title: 'File selected.',
        description: `${files.length} file(s) selected.`,
        status: 'info',
        duration: 5000,
        isClosable: true
      });
    }
  };

  const updateFileAndPreview = (newFile: File | null): void => {
    if (newFile != null) {
      const url = URL.createObjectURL(newFile);
      setPreviewUrl(url);
      setFile(newFile);
    }
  };

  const removeFile = (): void => {
    setFile(null);
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Upload File</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4} align="stretch">
              {file != null ? (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  borderWidth="1px"
                  borderRadius="md"
                  p={3}
                  bg="gray.100"
                  width="full"
                >
                  <Box display="flex" alignItems="center">
                    <Icon as={file.type === 'application/pdf' ? AiFillFilePdf : AiOutlineFile} boxSize={6} mr={3} />
                    <Text>{file.name}</Text>
                  </Box>
                  <Button variant="ghost" colorScheme="red" size="sm" onClick={removeFile}>
                    <CloseIcon />
                  </Button>
                </Box>
              ) : (
                /* Show file upload box if no file is selected */
                <Box
                  borderWidth="2px"
                  borderStyle="dashed"
                  borderColor="gray.300"
                  borderRadius="md"
                  p={6}
                  textAlign="center"
                  onDrop={handleFileDrop}
                  onDragOver={(e) => {
                    e.preventDefault();
                  }}
                  cursor="pointer"
                >
                  <Text fontSize="lg" color="gray.500">
                    Drag and drop files here
                  </Text>
                  <Text fontSize="lg" color="gray.500">
                    or
                  </Text>
                  <Button variant="outline" colorScheme="blue" onClick={handleSelectFilesClick}>
                    Select file
                  </Button>
                  <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileChange} />
                </Box>
              )}

              <FormControl isRequired>
                <Select
                  placeholder="Select attachment type"
                  bg="white"
                  onChange={(e) => {
                    setAttachmentType(e.target.value);
                  }}
                  disabled={fixedAttachmentType != null}
                  value={attachmentType}
                >
                  {attachmentTypes?.map((type) => (
                    <option key={type.value} value={type.value}>
                      {type.name}
                    </option>
                  ))}
                </Select>
              </FormControl>

              <FormControl isRequired>
                <Input
                  placeholder="Use a descriptive name"
                  bg="white"
                  onChange={(e) => {
                    setAttachmentName(e.target.value);
                  }}
                />
              </FormControl>

              <FormControl>
                <Textarea
                  placeholder="Notes"
                  bg="white"
                  onChange={(e) => {
                    setAttachmentNotes(e.target.value);
                  }}
                />
              </FormControl>
            </VStack>
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button
              colorScheme="blackAlpha"
              bg="black"
              color="white"
              _hover={{ bg: 'gray.700' }}
              onClick={handleUpload}
              isLoading={isLoading}
              isDisabled={fileUploading || file == null || attachmentName === ''}
            >
              Upload
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AttachmentModal;
