import * as Sentry from '@sentry/browser';

import { Risk } from '@utils/consts';
import { walletRisks } from '@utils/walletRisks.const';

export const getRiskLevel = (category, exposure): string => {
  if (!(category in walletRisks)) {
    const message = `No Category found for:  "${category}"`;
    console.error(message);
    const customError = new Error(message);
    Sentry.captureException(customError);
    return Risk.LOW;
  }
  const risk = walletRisks[category];
  const { risks } = risk;
  for (const { min, max, risk: riskLevel } of risks) {
    if (exposure > min && exposure <= max) {
      return Risk[riskLevel];
    }
  }
  if (exposure !== 0) {
    console.error(`No Risk level found for category ${category} with exposure ${exposure}`);
  }

  return Risk.LOW;
};
