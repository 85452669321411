import { formatDate } from '@features/panels/alertsPanel/utils/alertUtils';

export const mapScreeningHit = (
  hit: any
): {
  entityName: string;
  sourceList: string;
  category: string;
  score: string;
  entityType: string | undefined;
  additionalFields: any;
} => {
  const result = hit.result ?? {};
  if (hit.recordType === 'Castellum') {
    return {
      entityName: hit.resultName ?? 'N/A',
      sourceList: result.listEntry?.listSource?.name ?? 'N/A',
      category: result.listEntry?.listSource?.category?.name ?? 'N/A',
      score: result.score != null ? `${(result.score * 100).toFixed(2)}%` : 'N/A',
      entityType: result?.listEntry?.type?.name,
      additionalFields: {
        firstName: result?.listEntry?.name?.firstName,
        lastName: result?.listEntry?.name?.lastName,
        aliases:
          result?.listEntry?.aliases
            ?.map((alias) => {
              if (alias.fullName === undefined) {
                if (alias.firstName !== undefined && alias.lastName !== undefined) {
                  return `${alias.firstName} ${alias.lastName}`;
                }
              }
              return alias.fullName;
            })
            .filter(Boolean) ?? [],
        citizenships:
          result?.listEntry?.citizenships
            ?.map((citizenship) => {
              if (citizenship.official_name === undefined) {
                return citizenship.name;
              }
              return citizenship.official_name;
            })
            .filter(Boolean) ?? [],
        birthDates:
          result?.listEntry?.dobs
            ?.map((dob) => {
              if (dob.raw !== undefined) return dob.raw;
              if (dob.start !== undefined && dob.end !== undefined) {
                const startFormatted = formatDate(dob.start);
                const endFormatted = formatDate(dob.end);
                return startFormatted === endFormatted ? startFormatted : `${startFormatted} - ${endFormatted}`;
              }
              return undefined;
            })
            .filter(Boolean) ?? [],
        addresses: result?.listEntry?.addresses?.map((address) => address.address).filter(Boolean) ?? []
      }
    };
  } else if (hit.recordType === 'OpenSanctions') {
    return {
      entityName: hit.resultName ?? 'N/A',
      sourceList: result.datasets?.join('; ') ?? 'N/A',
      category: result.properties.topics?.[0] ?? 'N/A',
      score: result.score != null ? `${(result.score * 100).toFixed(2)}%` : 'N/A',
      entityType: result.schema,
      additionalFields: {
        firstName: result.properties.firstName?.join(' '),
        lastName: result.properties.lastName?.join(' '),
        aliases: result.properties.alias ?? [],
        citizenships: result.properties.nationality ?? [],
        birthDates: result.properties.birthDate ?? [],
        addresses: result.properties.address ?? []
      }
    };
  }
  throw new Error('Unsupported record type');
};
