import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Flex,
  Heading,
  HStack,
  Select,
  VStack,
  useDisclosure
} from '@chakra-ui/react';
import { useState } from 'react';
import { Form, Field } from 'react-final-form';
import { useNavigate } from 'react-router-dom';

import { useProfileWizard } from '@context/ProfileWizardContext';
import AttachmentModal from '@features/profile-wizard/components/common/AddAttachmentModal';
import useQueryParams from '@hooks/useQueryParams';
import { PROFILE_TYPE, type IProfile } from '@models/profileTypes';
import { useUpdateProfileMutation, useGetProfileAttachmentsQuery } from '@services/canaria.services';

import { IndividualPrimaryPartyForm, EntityPrimaryPartyForm } from '../forms/primaryParty';
interface IProfileWithRelationType extends IProfile {
  relationType?: string;
}

interface ProfileFormProps {
  profile?: IProfileWithRelationType;
  onSubmit: (values: any) => void;
  isRelatedParty?: boolean;
  relationType?: string;
}

export const AddPrimaryPartyForm: React.FC<ProfileFormProps> = ({
  profile,
  onSubmit,
  isRelatedParty = false,
  relationType
}) => {
  const [currentProfileType, setCurrentProfileType] = useState<PROFILE_TYPE | undefined>(
    profile?.resourcetype ?? PROFILE_TYPE.INDIVIDUAL
  );
  const setValue = ([field, value], state, { changeValue }): void => {
    changeValue(state, field, () => value);
  };

  const handleSubmit = async (values): Promise<void> => {
    onSubmit(values);
  };

  // We set the form id so we can submit the form externally
  return (
    <Form
      onSubmit={handleSubmit}
      mutators={{ setValue }}
      initialValues={{
        ...profile,
        relationType,
        profileType: currentProfileType,
        // Individual Profile
        citizenship: profile?.citizenship?.id,
        countryOfResidence: profile?.countryOfResidence?.id,
        idIssuer: profile?.idIssuer?.id,
        placeOfBirth: profile?.placeOfBirth?.id,
        gender: profile?.gender?.value,
        idType: profile?.idType?.value,
        // Entity Profile
        placeOfEstablishment: profile?.placeOfEstablishment?.id
      }}
      render={({ handleSubmit, form }) => (
        <Box id="IndividualPrimaryParty" as="form" onSubmit={handleSubmit} w="100%">
          <VStack spacing={4} align="stretch" p={5} boxShadow="md" bg="white">
            {isRelatedParty && (
              <Flex gap={4}>
                <HStack flex="1">
                  <FormControl id="profileType" isRequired>
                    <FormLabel>Profile Type</FormLabel>
                    <Field name="profileType" parse={(value) => value ?? ''}>
                      {({ input }) => (
                        <Select
                          {...input}
                          id="profileType"
                          onChange={(e) => {
                            input.onChange(e);
                            setCurrentProfileType(e.target.value as PROFILE_TYPE);
                          }}
                          disabled={profile?.resourcetype != null}
                        >
                          <option value={PROFILE_TYPE.INDIVIDUAL}>Individual profile</option>
                          <option value={PROFILE_TYPE.ENTITY}>Entity profile</option>
                        </Select>
                      )}
                    </Field>
                  </FormControl>
                </HStack>
                <HStack flex="1">
                  <FormControl id="relationType" isRequired>
                    <FormLabel>Relation Type</FormLabel>
                    <Field name="relationType" parse={(value) => value ?? ''}>
                      {({ input }) => (
                        <Select {...input} id="relationType">
                          <option value="">Select relation type</option>
                          <option value="PARENT_ENTITY/CONTROLLING_ENTITY">Parent Entity/Controlling Entity</option>
                          <option value="ULTIMATE_BENEFICIAL_OWNER">Ultimate Beneficial Owner</option>
                          <option value="CONTROL_PERSON">Control Person</option>
                          <option value="COMPLIANCE_OFFICER">Compliance Officer</option>
                          <option value="OTHER">Other</option>
                        </Select>
                      )}
                    </Field>
                  </FormControl>
                </HStack>
              </Flex>
            )}
            {currentProfileType === PROFILE_TYPE.INDIVIDUAL ? (
              <IndividualPrimaryPartyForm form={form} />
            ) : (
              <EntityPrimaryPartyForm />
            )}
          </VStack>
        </Box>
      )}
    />
  );
};

interface ProfilePageProps {
  orgId: string;
  profile: IProfile;
}

const AddPrimaryPartyPage: React.FC<ProfilePageProps> = ({ orgId, profile }) => {
  const navigate = useNavigate();
  const { currentStep, maxSteps } = useProfileWizard();
  const { queryParams, setQueryParams } = useQueryParams();
  const [updateProfile, { isLoading }] = useUpdateProfileMutation();
  const { data: attachments } = useGetProfileAttachmentsQuery({ orgId, profileId: profile.id });
  const { isOpen, onOpen, onClose } = useDisclosure();

  const identificationAttachment = attachments?.find((attachment) => attachment.attachmentType === 'IDENTIFICATION');

  const onSubmit = async (values): Promise<void> => {
    if (profile.resourcetype === PROFILE_TYPE.INDIVIDUAL) {
      await updateProfile({
        orgId,
        profileId: profile.id,
        address: values.address,
        citizenship: values.citizenship,
        city: values.city,
        postalCode: values.postalCode,
        countryOfResidence: values.countryOfResidence,
        dateOfBirth: values.dateOfBirth,
        email: values.email,
        fullLegalName: values.fullLegalName,
        gender: values.gender,
        idIssuer: values.idIssuer,
        idNumber: values.idNumber,
        idType: values.idType,
        natureOfEmployment: values.natureOfEmployment,
        placeOfBirth: values.placeOfBirth,
        state: values.state
      }).unwrap();
    } else {
      await updateProfile({
        orgId,
        profileId: profile.id,
        fullLegalName: values.fullLegalName,
        dbaTradeName: values.dbaTradeName,
        entityFormationType: values.entityFormationType,
        placeOfEstablishment: values.placeOfEstablishment,
        dateOfEstablishment: values.dateOfEstablishment,
        uniqueIdentificationNumber: values.uniqueIdentificationNumber,
        registeredBusinessAddress: values.registeredBusinessAddress,
        mailingAddress: values.mailingAddress,
        physicalAddress: values.physicalAddress,
        companyWebsite: values.companyWebsite
      });
    }

    if (currentStep === maxSteps) {
      navigate(`/dashboard/profiles/${profile.id}`);
      return;
    }
    setQueryParams({ ...queryParams, step: currentStep + 1 });
  };

  return (
    <Box w="90%">
      <Heading as="h1" size="lg" textAlign="center">
        2. Primary Party
      </Heading>
      <Flex justifyContent="flex-end" mb="4">
        {identificationAttachment == null ? (
          <Button colorScheme="green" onClick={onOpen}>
            Scan ID
          </Button>
        ) : (
          <Button
            bg="black"
            color="white"
            onClick={() => {
              window.open(identificationAttachment.file);
            }}
          >
            Attachment uploaded
          </Button>
        )}
      </Flex>
      <AddPrimaryPartyForm profile={profile} onSubmit={onSubmit} />
      <AttachmentModal
        isOpen={isOpen}
        onClose={onClose}
        orgId={orgId}
        profileId={profile.id}
        fixedAttachmentType="IDENTIFICATION"
      />
      <Flex justifyContent="center" gap={4} mt={4}>
        <Button
          bg="gray.300"
          onClick={() => {
            setQueryParams({ ...queryParams, step: currentStep - 1 });
          }}
        >
          Back
        </Button>
        <Button type="submit" form="IndividualPrimaryParty" bg="black" color="white" isLoading={isLoading}>
          {currentStep === maxSteps ? 'Finish' : 'Next'}
        </Button>
      </Flex>
    </Box>
  );
};

export default AddPrimaryPartyPage;
