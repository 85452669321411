// prettier-ignore
export const CATEGORY_NAMES = {
  'child abuse material': 'Child abuse material',
  'darknet market': 'Darknet market',
  'high risk jurisdiction': 'High risk jurisdiction',
  'ransomware': 'Ransomware',
  'sanctioned entity': 'Sanctioned entity',
  'sanctioned jurisdiction': 'Sanctioned jurisdiction',
  'sanctions': 'Sanctions',
  'special measures': 'Special measures',
  'terrorist financing': 'Terrorist financing',
  'fraud shop': 'Fraud shop',
  'fee': 'Fee',
  'high risk exchange': 'High risk exchange',
  'illicit actor-org': 'Illicit actor-org',
  'malware': 'Malware',
  'mixing': 'Mixing',
  'online pharmacy': 'Online pharmacy',
  'scam': 'Scam',
  'stolen funds': 'Stolen funds',
  'atm': 'ATM',
  'decentralized exchange contract': 'Decentralized exchange contract',
  'decentralized exchange': 'Decentralized exchange contract',
  'gambling': 'Gambling',
  'lending': 'Lending',
  'lending contract': 'Lending contract',
  'p2p exchange': 'P2P exchange',
  'protocol privacy': 'Protocol privacy',
  'seized funds': 'Seized funds',
  'no category': 'No category',
  'none': 'No category',
  'bridge': 'Bridge',
  'exchange': 'Exchange',
  'hosted wallet': 'Hosted wallet',
  'ico': 'ICO',
  'infrastructure as a service': 'Infrastructure as a service',
  'merchant services': 'Merchant services',
  'mining': 'Mining',
  'mining pool': 'Mining pool',
  'minting and burning': 'Minting and burning',
  'nft platform - collection': 'NFT platform - collection',
  'other': 'Other',
  'smart contract': 'Smart contract',
  'token smart contract': 'Token smart contract',
  'unnamed service': 'Unnamed service',
  'no kyc exchange': 'No KYC exchange',
  'unspent': 'Unspent',
  'untraced': 'Untraced',
  'tracked to self': 'Tracked to self',
  'dust': 'Dust',
  'fees': 'Fees',
  'org': 'Organization'
};

// prettier-ignore
export const walletRisks = {
  'child abuse material': {
    risks: [{ min: 0, max: 100, risk: 'SEVERE' }]
  },
  'darknet market': {
    risks: [{ min: 0, max: 100, risk: 'SEVERE' }]
  },
  'high risk jurisdiction': {
    risks: [{ min: 0, max: 100, risk: 'SEVERE' }]
  },
  'ransomware': {
    risks: [{ min: 0, max: 100, risk: 'SEVERE' }]
  },
  'sanctioned entity': {
    risks: [{ min: 0, max: 100, risk: 'SEVERE' }]
  },
  'sanctioned jurisdiction': {
    risks: [{ min: 0, max: 100, risk: 'SEVERE' }]
  },
  'sanctions': {
    risks: [{ min: 0, max: 100, risk: 'SEVERE' }]
  },
  'special measures': {
    risks: [{ min: 0, max: 100, risk: 'SEVERE' }]
  },
  'terrorist financing': {
    risks: [{ min: 0, max: 100, risk: 'SEVERE' }]
  },
  'fraud shop': {
    risks: [
      { min: 10, max: 100, risk: 'HIGH' },
      { min: 0, max: 10, risk: 'MEDIUM' }
    ]
  },
  'high risk exchange': {
    risks: [
      { min: 10, max: 100, risk: 'HIGH' },
      { min: 0, max: 10, risk: 'MEDIUM' }
    ]
  },
  'illicit actor-org': {
    risks: [
      { min: 10, max: 100, risk: 'HIGH' },
      { min: 0, max: 10, risk: 'MEDIUM' }
    ]
  },
  'malware': {
    risks: [
      { min: 10, max: 100, risk: 'HIGH' },
      { min: 0, max: 10, risk: 'MEDIUM' }
    ]
  },
  'mixing': {
    risks: [
      { min: 10, max: 100, risk: 'HIGH' },
      { min: 0, max: 10, risk: 'MEDIUM' }
    ]
  },
  'online pharmacy': {
    risks: [
      { min: 10, max: 100, risk: 'HIGH' },
      { min: 0, max: 10, risk: 'MEDIUM' }
    ]
  },
  'scam': {
    risks: [
      { min: 10, max: 100, risk: 'HIGH' },
      { min: 0, max: 10, risk: 'MEDIUM' }
    ]
  },
  'stolen funds': {
    risks: [
      { min: 10, max: 100, risk: 'HIGH' },
      { min: 0, max: 10, risk: 'MEDIUM' }
    ]
  },
  'atm': {
    risks: [
      { min: 10, max: 100, risk: 'MEDIUM' },
      { min: 0, max: 10, risk: 'LOW' }
    ]
  },
  'decentralized exchange contract': {
    risks: [
      { min: 10, max: 100, risk: 'MEDIUM' },
      { min: 0, max: 10, risk: 'LOW' }
    ]
  },
  'decentralized exchange': {
    risks: [
      { min: 10, max: 100, risk: 'MEDIUM' },
      { min: 0, max: 10, risk: 'LOW' }
    ]
  },
  'gambling': {
    risks: [
      { min: 10, max: 100, risk: 'MEDIUM' },
      { min: 0, max: 10, risk: 'LOW' }
    ]
  },
  'lending': {
    risks: [
      { min: 10, max: 100, risk: 'MEDIUM' },
      { min: 0, max: 10, risk: 'LOW' }
    ]
  },
  'lending contract': {
    risks: [
      { min: 10, max: 100, risk: 'MEDIUM' },
      { min: 0, max: 10, risk: 'LOW' }
    ]
  },
  'p2p exchange': {
    risks: [
      { min: 10, max: 100, risk: 'MEDIUM' },
      { min: 0, max: 10, risk: 'LOW' }
    ]
  },
  'protocol privacy': {
    risks: [
      { min: 10, max: 100, risk: 'MEDIUM' },
      { min: 0, max: 10, risk: 'LOW' }
    ]
  },
  'seized funds': {
    risks: [
      { min: 10, max: 100, risk: 'MEDIUM' },
      { min: 0, max: 10, risk: 'LOW' }
    ]
  },
  'no category': {
    risks: [{ min: 0, max: 100, risk: 'LOW' }]
  },
  'none': {
    risks: [{ min: 0, max: 100, risk: 'LOW' }]
  },
  'bridge': {
    risks: [{ min: 0, max: 100, risk: 'LOW' }]
  },
  'exchange': {
    risks: [{ min: 0, max: 100, risk: 'LOW' }]
  },
  'fee': {
    risks: [{ min: 0, max: 100, risk: 'LOW' }]
  },
  'hosted wallet': {
    risks: [{ min: 0, max: 100, risk: 'LOW' }]
  },
  'ico': {
    risks: [{ min: 0, max: 100, risk: 'LOW' }]
  },
  'infrastructure as a service': {
    risks: [{ min: 0, max: 100, risk: 'LOW' }]
  },
  'merchant services': {
    risks: [{ min: 0, max: 100, risk: 'LOW' }]
  },
  'mining': {
    risks: [{ min: 0, max: 100, risk: 'LOW' }]
  },
  'mining pool': {
    risks: [{ min: 0, max: 100, risk: 'LOW' }]
  },
  'minting and burning': {
    risks: [{ min: 0, max: 100, risk: 'LOW' }]
  },
  'nft platform - collection': {
    risks: [{ min: 0, max: 100, risk: 'LOW' }]
  },
  'other': {
    risks: [{ min: 0, max: 100, risk: 'LOW' }]
  },
  'smart contract': {
    risks: [{ min: 0, max: 100, risk: 'LOW' }]
  },
  'token smart contract': {
    risks: [{ min: 0, max: 100, risk: 'LOW' }]
  },
  'unnamed service': {
    risks: [{ min: 0, max: 100, risk: 'LOW' }]
  },
  'no kyc exchange': {
    risks: [
      { min: 10, max: 100, risk: 'MEDIUM' },
      { min: 0, max: 10, risk: 'LOW' }
    ]
  },
  'unspent': {
    risks: [{ min: 0, max: 100, risk: 'LOW' }]
  },
  'untraced': {
    risks: [{ min: 0, max: 100, risk: 'LOW' }]
  },
  'tracked to self': {
    risks: [{ min: 0, max: 100, risk: 'LOW' }]
  },
  'dust': {
    risks: [{ min: 0, max: 100, risk: 'LOW' }]
  },
  'fees': {
    risks: [{ min: 0, max: 100, risk: 'LOW' }]
  },
  'org': {
    risks: [{ min: 0, max: 100, risk: 'LOW' }]
  }
};
