export const loadLocalStorageState = <T>(itemName): T | undefined => {
  try {
    const serialState = localStorage.getItem(itemName);
    if (serialState === null) {
      return undefined;
    }
    return JSON.parse(serialState);
  } catch (err) {
    return undefined;
  }
};

export const saveLocalStorageState = (itemName: 'user' | 'auth', state): void => {
  try {
    const serialState = JSON.stringify(state);
    localStorage.setItem(itemName, serialState);
  } catch (err) {
    console.error(err);
  }
};
