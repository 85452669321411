import { Flex, Container, Button, Heading } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ProfileTable } from '@features/profile';
import { selectActiveOrgID } from '@features/user-settings/userSlice';

const Profiles: React.FC = () => {
  const activeOrgID = useSelector(selectActiveOrgID);
  if (activeOrgID == null) {
    throw new Error('activeOrgID is null');
  }
  const navigate = useNavigate();
  return (
    <Container maxW="8xl">
      <Heading as="h1" size="lg" textAlign="center" mb={5}>
        Profiles
      </Heading>
      <Flex justifyContent="flex-end" mx="4">
        <Button
          onClick={() => {
            navigate('/dashboard/profile-wizard');
          }}
          colorScheme="green"
          mb={4}
        >
          Create Profile
        </Button>
      </Flex>
      <ProfileTable orgId={activeOrgID} />
    </Container>
  );
};

export default Profiles;
