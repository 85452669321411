import { Input } from '@chakra-ui/react';
import { useJsApiLoader, StandaloneSearchBox, type Libraries } from '@react-google-maps/api';
import { useRef } from 'react';

import { extractAddressDetails } from '@utils/googlePlaces';

import { GOOGLE_MAPS_API_KEY } from '../../env';

interface PlacePickerProps {
  input: any;
  onPlaceSelected?: (place: google.maps.places.PlaceResult) => void;
  completeAddress?: boolean;
}

const libraries: Libraries = ['places'];

const PlacePicker: React.FC<PlacePickerProps> = ({ input, onPlaceSelected, completeAddress = false }) => {
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries
  });
  const searchBoxRef = useRef<google.maps.places.SearchBox | null>(null);

  const handleLoad = (ref: google.maps.places.SearchBox): void => {
    searchBoxRef.current = ref;
  };

  const onPlacesChanged = (): void => {
    if (searchBoxRef.current === null) return;
    const places = searchBoxRef.current.getPlaces();
    if (places != null && places.length > 0) {
      const place = places[0];
      const { neighborhood, subLocality } = extractAddressDetails(place);
      if (completeAddress) {
        input.onChange(place.formatted_address);
      } else {
        input.onChange([place.name, neighborhood, subLocality].filter((name) => name !== '').join(', '));
      }
      if (onPlaceSelected != null) onPlaceSelected(place);
    }
  };
  if (isLoaded) {
    return (
      <>
        <StandaloneSearchBox onLoad={handleLoad} onPlacesChanged={onPlacesChanged}>
          <Input {...input} type="text" placeholder="Enter an address" />
        </StandaloneSearchBox>
      </>
    );
  }
  if (loadError != null) {
    return <div>Address field cannot be loaded right now.</div>;
  }
  return <div>Loading...</div>;
};

export default PlacePicker;
