export const getStatusText = (status: string): string => {
  switch (status) {
    case 'O':
      return 'Open';
    case 'C':
      return 'Closed';
    case 'R':
      return 'Under Review';
    case 'E':
      return 'Escalated';
    default:
      return 'Unknown';
  }
};

export const truncateAddress = (address: string): string => {
  return address.length > 10 ? `${address.slice(0, 3)}...${address.slice(-3)}` : address;
};

export const formatDate = (date: string): string => date.slice(0, 10).split('-').reverse().join('/');
