import { Text } from '@chakra-ui/react';
import { useState } from 'react';

import { RelatedProfileManager } from '@features/profile-wizard';
import { Loading } from '@features/shared/components';
import { useGetProfileByIdQuery } from '@services/canaria.services';

interface IRelatedPartiesSectionProps {
  orgId: string;
  profileId: string;
}
const RelatedPartiesSection: React.FC<IRelatedPartiesSectionProps> = ({ orgId, profileId }) => {
  const [showForm, setShowForm] = useState<boolean>(false);

  const { data: profile, isLoading } = useGetProfileByIdQuery({
    orgId,
    profileId
  });

  if (isLoading || profile == null) {
    return <Loading message="Loading related parties..." />;
  }

  return (
    <RelatedProfileManager orgId={orgId} profile={profile} showForm={showForm} setShowForm={setShowForm}>
      <Text fontSize="sm" align="center">
        No related parties added yet.{' '}
        <Text
          as="span"
          textDecoration="underline"
          cursor="pointer"
          onClick={() => {
            setShowForm(true);
          }}
        >
          Add a related party
        </Text>
      </Text>
    </RelatedProfileManager>
  );
};

export default RelatedPartiesSection;
