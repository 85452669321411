import { authLogoutAction, setTokens } from './authSlice';
import { store } from '../../state/store';
import analytics from '../analytics/analytics';

class AuthAPI {
  private readonly dispatch: (action: any) => void;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  login(refreshToken, accessToken): void {
    this.dispatch(setTokens({ refresh: refreshToken, access: accessToken }));
    analytics.trackLoggedIn();
  }

  logout(): void {
    if (!store.getState().auth.isLoggedIn) return;

    analytics.trackLoggedOut();
    localStorage.clear();
    this.dispatch(authLogoutAction());
  }
}

export default AuthAPI;
