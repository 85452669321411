import md5 from 'md5';
import isEmail from 'validator/lib/isEmail';
const toCamelCase = (str: string): string => {
  return str.replace(/([-_][a-z])/g, (group) => group.toUpperCase().replace('-', '').replace('_', ''));
};

export const convertObjectKeysToCamelCase = <T extends Record<string, any>>(obj: Record<string, any>): T => {
  if (obj == null || typeof obj !== 'object') {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map(convertObjectKeysToCamelCase) as unknown as T;
  }

  const newObj: Record<string, any> = {};
  for (const [key, value] of Object.entries(obj)) {
    newObj[toCamelCase(key)] = convertObjectKeysToCamelCase(value);
  }
  return newObj as T;
};

export const validateEmail = (email: string | null): boolean => {
  if (email == null) {
    return false;
  }
  return isEmail(email);
};

export const calculateMd5Hash = async (file: File): Promise<string> => {
  const buffer = await file.arrayBuffer();
  const hashHex = md5(new Uint8Array(buffer));

  if (hashHex === null) {
    throw new Error('Failed to calculate MD5 hash');
  }
  // Convert hex string to byte array
  const matches = hashHex.match(/.{2}/g);
  if (matches === null) {
    throw new Error('Invalid hash hex string');
  }
  const hashBytes = new Uint8Array(matches.map((byte) => parseInt(byte, 16)));

  // Convert to base64
  return btoa(String.fromCharCode.apply(null, [...hashBytes]));
};

export const uploadFileToSignedUrl = async (signedUrl: string, file: File): Promise<void> => {
  const md5HashBase64 = await calculateMd5Hash(file);

  const response = await fetch(signedUrl, {
    method: 'PUT',
    body: file,
    headers: {
      'Content-Type': file.type,
      'Content-Length': file.size.toString(),
      'Content-MD5': md5HashBase64,
      'x-goog-content-length-range': `0,${file.size}`
    }
  });
  if (!response.ok) throw new Error('Upload failed');
};
