import { Button as MaterialButton, ThemeProvider, createTheme } from '@mui/material';
import { MaterialReactTable, type MRT_ColumnDef } from 'material-react-table';
import { useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { PROFILE_TYPE } from '@models/profileTypes';

interface RelatedProfileListProps {
  isLoadingRelatedProfiles: boolean;
  relatedProfiles: any[];
  onClickEdit: (party: any) => void;
  onClickAdd: () => void;
}

const RelatedProfileList: React.FC<RelatedProfileListProps> = ({
  relatedProfiles,
  isLoadingRelatedProfiles,
  // onClickEdit,
  onClickAdd
}) => {
  const defaultMaterialTheme = createTheme();
  const navigate = useNavigate();

  const columns = useMemo<Array<MRT_ColumnDef<any>>>(
    () => [
      {
        id: 'id',
        header: 'ID',
        accessorKey: 'profile.id',
        size: 40,
        grow: 0
      },
      {
        id: 'name',
        header: 'Name',
        accessorKey: 'profile.fullLegalName'
      },
      {
        id: 'resourcetype',
        header: 'Type',
        accessorKey: 'profile.resourcetype',
        Cell: ({ row }) => {
          return row.original.profile.resourcetype === PROFILE_TYPE.INDIVIDUAL ? 'Individual' : 'Entity';
        }
      },
      {
        id: 'relationship',
        header: 'Relationship to primary Party',
        accessorKey: 'relationType.name'
      },
      {
        id: 'total_unresolved_hits',
        header: 'Actions',
        accessorKey: 'profile.totalUnresolvedHits',
        Cell: ({ row }) => {
          const totalUnresolvedHits = row.original.profile.totalUnresolvedHits;
          const relatedProfileId = row.original.profile.id;
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {totalUnresolvedHits > 0 && (
                <span style={{ color: 'red', marginRight: '8px' }}>{totalUnresolvedHits} unresolved hits</span>
              )}
              <MaterialButton
                variant="contained"
                size="small"
                sx={{ backgroundColor: 'black', color: 'white', textTransform: 'none' }}
                onClick={() => {
                  navigate(`/dashboard/profiles/${relatedProfileId}/screenings`);
                }}
              >
                Screening Hits
              </MaterialButton>
            </div>
          );
        }
      },
      {
        id: 'view',
        header: '',
        accessorKey: 'view',
        Cell: ({ row }) => (
          <MaterialButton
            component={Link}
            to={`/dashboard/profiles/${row.original.profile.id}`}
            variant="contained"
            color="primary"
            size="small"
          >
            View
          </MaterialButton>
        )
      }
    ],
    [navigate]
  );
  return (
    <ThemeProvider theme={defaultMaterialTheme}>
      <MaterialReactTable
        columns={columns}
        data={relatedProfiles ?? []}
        renderTopToolbarCustomActions={() => {
          return (
            <MaterialButton
              variant="contained"
              onClick={() => {
                onClickAdd();
              }}
            >
              Register new related party
            </MaterialButton>
          );
        }}
        state={{
          isLoading: isLoadingRelatedProfiles
        }}
        initialState={{ density: 'compact' }}
      />
    </ThemeProvider>
  );
};

export default RelatedProfileList;
