import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { Box, Flex, Text, Textarea, Button, VStack, Radio, RadioGroup, Stack, useToast, Grid } from '@chakra-ui/react';
import { AnimatePresence, motion } from 'framer-motion';
import { useState } from 'react';

import type { Inquiry } from '@features/panels/alertsPanel/types';
import { mapScreeningHit } from '@features/panels/alertsPanel/utils/screeningHit';

// @ts-expect-error suppressed the deprecation error but typescript still complains
const MotionBox = motion.create(Box);
const LineField = ({ label, value }: { label: string; value: string | undefined | null }): JSX.Element | null => {
  if (value === null || value === undefined) {
    return null;
  }
  return (
    <Box flex="1 0 21%">
      <Flex direction="column" justify="flex-start" height="100%">
        <Text fontSize="xs" color="gray.500" mb={1}>
          {label}
        </Text>
        <Text
          borderBottom="1px solid"
          borderColor="gray.300"
          pb={1}
          minHeight="3em"
          whiteSpace="normal"
          wordBreak="break-word"
        >
          {value}
        </Text>
      </Flex>
    </Box>
  );
};

const ListField = ({ label, items }: { label: string; items: Array<string | undefined> }): JSX.Element | null => {
  return (
    <VStack align="stretch" spacing={0} borderLeft="1px solid" borderColor="gray.300" pl={2} flex={1}>
      <Text fontSize="xs" color="gray.500">
        {label}
      </Text>
      {items.map(
        (item, index) =>
          item !== undefined && (
            <Text key={index} borderBottom="1px solid" borderColor="gray.300" pb={1}>
              {item}
            </Text>
          )
      )}
    </VStack>
  );
};

const MatchingScreeningHit = ({
  screeningHit,
  onResolve,
  isExpanded,
  onToggle
}: {
  screeningHit: Inquiry;
  onResolve: (id: number, resolution: string, notes: string) => void;
  isExpanded: boolean;
  onToggle: () => void;
}): JSX.Element => {
  const toast = useToast();
  const [resolution, setResolution] = useState<'TRUE_MATCH' | 'FALSE_POSITIVE' | 'NO_RESOLUTION'>(
    screeningHit.resolution as 'TRUE_MATCH' | 'FALSE_POSITIVE' | 'NO_RESOLUTION'
  );
  const [notes, setNotes] = useState(screeningHit.notes);

  const isResolved = screeningHit.resolution !== 'NO_RESOLUTION';

  const getButtonText = (): string => {
    if (isExpanded) {
      if (isResolved) {
        return resolution === 'TRUE_MATCH' ? 'True Match' : 'False Positive';
      }
      return 'Open Hit';
    } else {
      if (isResolved) {
        return resolution === 'TRUE_MATCH' ? 'True Match' : 'False Positive';
      }
      return 'Open Hit';
    }
  };

  const getButtonIcon = (): JSX.Element => {
    return isExpanded ? <ViewOffIcon /> : <ViewIcon />;
  };

  const mappedHit = mapScreeningHit(screeningHit);

  return (
    <Box borderWidth="1px" borderColor="black" borderStyle="solid" borderRadius="0" p={4} mb={4}>
      <Flex justifyContent="space-between" alignItems="center" gap={4}>
        <Text fontSize="sm" width="25%">
          Entity Name: {mappedHit.entityName}
        </Text>
        <Text fontSize="sm" width="25%">
          Source List: {mappedHit.sourceList}
        </Text>
        <Text fontSize="sm" width="20%">
          Category: {mappedHit.category}
        </Text>
        <Text fontSize="sm" width="15%">
          Score: {mappedHit.score}
        </Text>
        <Button
          onClick={onToggle}
          bg={isResolved ? (resolution === 'TRUE_MATCH' ? 'red.500' : '#BED902') : 'gray.200'}
          color={isResolved && resolution === 'TRUE_MATCH' ? 'white' : 'black'}
          _hover={{ bg: isResolved ? (resolution === 'TRUE_MATCH' ? 'red.600' : 'green.500') : 'gray.300' }}
          size="sm"
          borderWidth="1px"
          whiteSpace="normal"
          overflowWrap="break-word"
          textAlign="center"
          height="auto"
          minHeight="31px"
          py={0}
          width="200px"
        >
          <Flex alignItems="center" justifyContent="space-around" width="100%" padding="0 15px 0 15px">
            {getButtonText()}
            {getButtonIcon()}
          </Flex>
        </Button>
      </Flex>
      <AnimatePresence>
        {isExpanded && (
          <MotionBox
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3 }}
            overflow="hidden"
          >
            <Flex mt={4}>
              <VStack align="stretch" flex={1}>
                <Grid templateColumns="repeat(5, 1fr)" gap={4} width="100%">
                  <LineField label="First Name" value={mappedHit.additionalFields.firstName} />
                  <LineField label="Last Name" value={mappedHit.additionalFields.lastName} />
                  <LineField label="Occupation" value={screeningHit.result?.listEntry?.catchAll?.Occupation?.[0]} />
                  <LineField label="Entity Type" value={mappedHit.entityType} />
                  <LineField
                    label="List Names"
                    value={screeningHit.result?.listEntry?.listSource?.lists?.map((list) => list.name).join('; ')}
                  />
                </Grid>
                <Flex gap={4} width="100%" flexWrap="wrap">
                  <ListField label="Aliases" items={mappedHit.additionalFields.aliases} />
                  <ListField label="Citizenships" items={mappedHit.additionalFields.citizenships} />
                  <ListField label="Addresses" items={mappedHit.additionalFields.addresses} />
                  <ListField label="Dates of Birth" items={mappedHit.additionalFields.birthDates} />
                </Flex>
                <Flex gap={4} width="100%" flexWrap="wrap">
                  {Object.entries(screeningHit.result?.listEntry?.catchAll ?? {})
                    .filter(([key]) => key !== 'Occupation') // Skip 'Occupation' as it's already rendered
                    .map(([key, value]: [string, any]) => (
                      <LineField
                        key={key}
                        label={key}
                        value={
                          Array.isArray(value)
                            ? value?.join('; ')
                            : value !== null && value !== undefined
                              ? String(value)
                              : null
                        }
                      />
                    ))}
                </Flex>
              </VStack>
              <VStack align="stretch" width="200px" ml={4}>
                <Text fontSize="xs" color="gray.500">
                  Resolution
                </Text>
                <RadioGroup
                  onChange={(value) => {
                    setResolution(value as 'TRUE_MATCH' | 'FALSE_POSITIVE');
                  }}
                  value={resolution}
                  isDisabled={isResolved}
                >
                  <Stack>
                    <Radio value="TRUE_MATCH">True Match</Radio>
                    <Radio value="FALSE_POSITIVE">False Positive</Radio>
                  </Stack>
                </RadioGroup>
                <Text fontSize="xs" color="gray.500" mt={2}>
                  Notes
                </Text>
                <Textarea
                  value={notes}
                  onChange={(e) => {
                    setNotes(e.target.value);
                  }}
                  placeholder="Enter notes here"
                  size="sm"
                  isDisabled={isResolved}
                />
                <Button
                  mt={2}
                  bg="black"
                  color="white"
                  _hover={{ bg: 'gray.800' }}
                  onClick={() => {
                    if (resolution === 'NO_RESOLUTION' || notes.trim() === '') {
                      toast({
                        status: 'error',
                        title: 'Error',
                        description: 'Please select a resolution and add notes to resolve the match!',
                        isClosable: true
                      });
                      return;
                    }
                    onResolve(screeningHit.id, resolution, notes);
                  }}
                  isDisabled={isResolved || resolution === 'NO_RESOLUTION' || notes.trim() === ''}
                >
                  Resolve Match
                </Button>
                {isResolved && (
                  <Text fontSize="sm" color="gray.500" mt={2}>
                    This match has been resolved
                  </Text>
                )}
              </VStack>
            </Flex>
          </MotionBox>
        )}
      </AnimatePresence>
    </Box>
  );
};

export default MatchingScreeningHit;
