import { Box, Grid, GridItem, Heading, Text, Textarea } from '@chakra-ui/react';

const AddressIdentificationSection: React.FC<{
  addressIdentifications: any[];
}> = ({ addressIdentifications }) => {
  return (
    <Box outline="1px solid black" mt={4} pl={5} pr={5} pt={4} pb={4} bg="white">
      <Heading size="sm">Address Identification</Heading>
      {addressIdentifications != null ? (
        addressIdentifications.length === 0 ? (
          <Grid templateColumns="auto" pt={4} pb={4}>
            <GridItem>
              <Text>No address identification found</Text>
            </GridItem>
          </Grid>
        ) : (
          addressIdentifications.map((ident, idx) => (
            <Grid key={idx} templateColumns="150px auto" pt={8}>
              <GridItem borderBottom="1px" borderColor="gray.300">
                <Text as="i">Name</Text>
              </GridItem>
              <GridItem borderBottom="1px" borderColor="gray.300">
                <Text pl="1ch">{ident.name}</Text>
              </GridItem>
              <GridItem borderBottom="1px" borderColor="gray.300">
                <Text as="i">Category</Text>
              </GridItem>
              <GridItem borderBottom="1px" borderColor="gray.300">
                <Text pl="1ch">{ident.category}</Text>
              </GridItem>
              <GridItem>
                <Text as="i">Description</Text>
              </GridItem>
              <GridItem>
                <Textarea isReadOnly pl="1ch" value={ident.description.replaceAll('; ', '\n')} />
              </GridItem>
            </Grid>
          ))
        )
      ) : (
        <></>
      )}
    </Box>
  );
};

export default AddressIdentificationSection;
