import EditableDate from './EditableDate';
import EditableSelect from './EditableSelect';
import EditableText from './EditableText';

interface EditableFieldProps {
  label: string;
  value: string | number | Date;
  type: 'text' | 'select' | 'date';
  options?: Array<{ name: string; value: string | number }>;
  onConfirmChange: (value: string | number | Date) => void;
  isDisabled?: boolean;
  allowNull?: boolean;
}

const EditableField: React.FC<EditableFieldProps> = ({
  label,
  value,
  type,
  options = [],
  onConfirmChange,
  isDisabled,
  allowNull
}) => {
  switch (type) {
    case 'text':
      return (
        <EditableText label={label} value={value as string} onConfirmChange={onConfirmChange} isDisabled={isDisabled} />
      );
    case 'select':
      return (
        <EditableSelect
          label={label}
          value={value as string}
          options={options}
          onConfirmChange={onConfirmChange}
          isDisabled={isDisabled}
          allowNull={allowNull}
        />
      );
    case 'date':
      return (
        <EditableDate label={label} value={value as Date} onConfirmChange={onConfirmChange} isDisabled={isDisabled} />
      );
    default:
      return null;
  }
};

export default EditableField;
