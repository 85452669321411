import { SearchIcon, EmailIcon } from '@chakra-ui/icons';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  VStack,
  Checkbox,
  Text,
  Box,
  Flex,
  Input,
  HStack,
  Tag,
  TagLabel,
  TagCloseButton,
  Alert,
  AlertIcon,
  useToast,
  InputGroup,
  InputLeftElement
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import ReactQuill from 'react-quill';
import { useSelector } from 'react-redux';

import 'react-quill/dist/quill.snow.css';
import { selectActiveOrgID } from '@features/user-settings/userSlice';
import { PROFILE_STATUS_CODE } from '@models/profileTypes';
import {
  useGetCategorizedProfileAttachmentTypesQuery,
  useGetOrganizationQuery,
  useGetProfileByIdQuery,
  useCreateRFIMutation,
  useUpdateProfileStatusMutation
} from '@services/canaria.services';
import { validateEmail } from '@services/utils';

// in order to use the Do format
// aka so we can have ordinal indicators, "st", "nd", "rd", "th"
dayjs.extend(advancedFormat);

interface RequestForInformationModalProps {
  isOpen: boolean;
  onClose: () => void;
  profileId: string;
}

// Since we load the field straight up from what the profile provides,
// we need to ignore some fields that we don't want to show up in the RFI
const ProfileFieldsToIgnore = [
  'id',
  'createdAt',
  'updatedAt',
  'profileGroup',
  'profileAttachments',
  'profileGroupName',
  'resourceType',
  'totalUnresolvedHits',
  'totalUnresolvedRelatedProfilesHits',
  'isPrimary',
  'attachments',
  'relatedProfiles',
  'hasOpenItems'
];

const RequiredFieldsSelector: React.FC<{
  profileFields: string[];
  setSelectedFields: (value: string[]) => void;
}> = ({ profileFields, setSelectedFields }) => {
  const parsedProfileFields = profileFields.map((field) =>
    field
      .split(/(?=[A-Z])/)
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
  );
  const [fieldSearch, setFieldSearch] = useState('');
  const [fields, setFields] = useState<string[]>([]);

  const filteredFields = useMemo(
    () => parsedProfileFields.filter((field) => field.toLowerCase().includes(fieldSearch.toLowerCase())),
    [parsedProfileFields, fieldSearch]
  );

  const handleFieldToggle = (field: string): void => {
    const updatedFields = fields.includes(field) ? fields.filter((item) => item !== field) : [...fields, field];
    setFields(updatedFields);
    setSelectedFields(updatedFields);
  };

  return (
    <Box>
      <Text fontWeight="bold" fontSize="lg">
        Required Fields:
      </Text>
      <Text fontSize="sm" color="gray.500" mb={4}>
        Add the data fields that the Point of Contact must provide as part of the intake form. All fields included in
        this section will be marked as required in the RFI intake form.
      </Text>
      <Box border="1px" borderColor="gray.200" borderRadius="md" p={4}>
        <InputGroup mb={4}>
          <InputLeftElement pointerEvents="none">
            <SearchIcon color="gray.300" />
          </InputLeftElement>
          <Input
            placeholder="Search fields"
            value={fieldSearch}
            onChange={(e) => {
              setFieldSearch(e.target.value);
            }}
          />
        </InputGroup>
        <Flex flexWrap="wrap" gap={2}>
          {filteredFields.map((field) => (
            <Checkbox
              key={field}
              isChecked={fields.includes(field)}
              onChange={() => {
                handleFieldToggle(field);
              }}
              width="auto"
              minWidth="200px"
              flexGrow={1}
              flexBasis="calc(25% - 8px)"
            >
              {field
                .split(/(?=[A-Z])/)
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ')}
            </Checkbox>
          ))}
        </Flex>
      </Box>
    </Box>
  );
};

const AdditionalRecipientsComponent: React.FC<{
  additionalRecipients: string[];
  setAdditionalRecipients: (value: string[]) => void;
}> = ({ additionalRecipients, setAdditionalRecipients }) => {
  const [emailInput, setEmailInput] = useState('');
  const [emailError, setEmailError] = useState('');

  const handleEmailInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setEmailInput(e.target.value);
    setEmailError('');
  };

  const handleAddEmail = (): void => {
    const trimmedEmail = emailInput.trim();
    if (trimmedEmail !== '' && validateEmail(trimmedEmail) && !additionalRecipients.includes(trimmedEmail)) {
      setAdditionalRecipients([...additionalRecipients, trimmedEmail]);
      setEmailInput('');
      setEmailError('');
    } else if (trimmedEmail !== '' && !validateEmail(trimmedEmail)) {
      setEmailError('Invalid email address');
    }
  };

  const handleEmailInputKeyPress = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleAddEmail();
    }
  };

  const handleRemoveEmail = (email: string): void => {
    setAdditionalRecipients(additionalRecipients.filter((e) => e !== email));
  };

  return (
    <Box>
      <Text fontWeight="bold" fontSize="lg">
        Additional Recipients:
      </Text>
      <Text fontSize="sm" color="gray.500" mb={4}>
        Add any additional recipients to the RFI notification email.
      </Text>
      <VStack align="stretch" spacing={2}>
        <HStack>
          <Input
            placeholder="Enter email address"
            value={emailInput}
            onChange={handleEmailInputChange}
            onKeyPress={handleEmailInputKeyPress}
            isInvalid={emailError !== ''}
          />
          <Button onClick={handleAddEmail} isDisabled={!validateEmail(emailInput.trim())}>
            Add
          </Button>
        </HStack>
        {emailError !== '' && (
          <Alert status="error" borderRadius="md">
            <AlertIcon />
            {emailError}
          </Alert>
        )}
        <Flex flexWrap="wrap" gap={2}>
          {additionalRecipients.map((email) => (
            <Tag key={email} size="md" borderRadius="full" variant="solid" colorScheme="blue">
              <TagLabel>{email}</TagLabel>
              <TagCloseButton
                onClick={() => {
                  handleRemoveEmail(email);
                }}
              />
            </Tag>
          ))}
        </Flex>
      </VStack>
    </Box>
  );
};

const NoteToPointOfContactComponent: React.FC<{
  note: string;
  setNote: (value: string) => void;
}> = ({ note, setNote }) => {
  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
      ['link', 'image'],
      ['clean']
    ]
  };

  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image'
  ];

  return (
    <Box>
      <Text fontWeight="bold" fontSize="lg">
        Note to Point of Contact:
      </Text>
      <Text fontSize="sm" color="gray.500" mb={4}>
        Please edit the following email template to include any instructions for the Point of Contact receiving this
        notification. Ensure that the Organization Name and Profile Group are retained in the email.
      </Text>
      <ReactQuill
        theme="snow"
        value={note}
        onChange={setNote}
        modules={modules}
        formats={formats}
        style={{ height: '200px', marginBottom: '50px' }}
      />
    </Box>
  );
};

const RequiredAttachmentsComponent: React.FC<{
  categorizedAttachmentTypes: any;
  setSelectedAttachments: (value: string[]) => void;
}> = ({ categorizedAttachmentTypes, setSelectedAttachments }) => {
  const [selectedAttachments, setLocalSelectedAttachments] = useState<string[]>([]);

  const handleAttachmentToggle = (attachment: string): void => {
    const updatedAttachments = selectedAttachments.includes(attachment)
      ? selectedAttachments.filter((item) => item !== attachment)
      : [...selectedAttachments, attachment];

    setLocalSelectedAttachments(updatedAttachments);
    setSelectedAttachments(updatedAttachments);
  };

  return (
    <Box>
      <Text fontWeight="bold" fontSize="lg">
        Required Attachments:
      </Text>
      <Text fontSize="sm" color="gray.500" mb={4}>
        Select the set of documents that the Point of Contact needs to provide to complete the profile. All selected
        documents will be marked as required in the RFI Intake Form.
      </Text>
      <Box border="1px" borderColor="gray.200" borderRadius="md" p={4}>
        <VStack align="stretch" spacing={4}>
          {Object.entries(categorizedAttachmentTypes ?? {}).map(([categoryName, attachments]) => (
            <Box key={categoryName}>
              <Text fontWeight="bold" mb={2} color="blue.600">
                {categoryName}
              </Text>
              <Flex align="start" gap={2} pl={4} flexWrap="wrap">
                {(attachments as Array<{ value: string; name: string }>).map((attachment) => (
                  <Checkbox
                    key={attachment.value}
                    isChecked={selectedAttachments.includes(attachment.value)}
                    onChange={() => {
                      handleAttachmentToggle(attachment.value);
                    }}
                    colorScheme="blue"
                  >
                    {attachment.name}
                  </Checkbox>
                ))}
              </Flex>
            </Box>
          ))}
        </VStack>
      </Box>
    </Box>
  );
};

const RequestForInformationModal: React.FC<RequestForInformationModalProps> = ({ isOpen, onClose, profileId }) => {
  const activeOrgID = useSelector(selectActiveOrgID);
  const noteRef = useRef('');
  const selectedAttachmentsRef = useRef<string[]>([]);
  const selectedFieldsRef = useRef<string[]>([]);
  const [additionalRecipients, setAdditionalRecipients] = useState<string[]>([]);
  const { data: categorizedAttachmentTypes } = useGetCategorizedProfileAttachmentTypesQuery(null);
  const { data: organization } = useGetOrganizationQuery(activeOrgID as string);
  const { data: profile } = useGetProfileByIdQuery({ orgId: activeOrgID as string, profileId });
  const profileFields = useMemo(
    () => Object.keys(profile ?? {}).filter((field) => !ProfileFieldsToIgnore.includes(field)),
    [profile]
  );
  const [createRFI] = useCreateRFIMutation();
  const [updateProfileStatus] = useUpdateProfileStatusMutation();
  const toast = useToast();

  const setNote = useCallback((value: string) => {
    noteRef.current = value;
  }, []);

  const setSelectedAttachments = useCallback((value: string[]) => {
    selectedAttachmentsRef.current = value;
  }, []);

  const setSelectedFields = useCallback((value: string[]) => {
    selectedFieldsRef.current = value;
  }, []);

  useEffect(() => {
    if (isOpen) {
      const defaultText = `
        <p>Dear <strong>${profile?.pointOfContactName ?? profile?.name ?? profile?.fullLegalName ?? '[POC Name]'}</strong>,<br><br>
        As part of the review of the information provided for your application as <strong>${profile?.profileGroup?.name ?? '[Profile Group]'}</strong> with <strong>${organization?.name ?? '[Organization Name]'}</strong>, we need your help to provide the following information and documents regarding <strong>${profile?.name ?? '[Profile Name]'}</strong>. This information and documentation are needed to complement the information you previously sent on ${dayjs(profile?.createdAt).format('MMMM Do, YYYY') ?? '[Date of Initial Submission]'}.<br><br>
        Please use the link in this notification email to respond and upload the documents using a secure transfer process. The information and documents will be handled according to our Privacy Policy.<br><br>
        If you have any comments, questions, or concerns about this request for information, please get in touch with us at support@canariaconsulting.com.</p>
      `;
      setNote(defaultText);
      setSelectedAttachments([]);
      setSelectedFields([]);
      setAdditionalRecipients([]);
    }
  }, [isOpen, profile, organization, setNote, setSelectedAttachments, setSelectedFields, setAdditionalRecipients]);

  const handleSubmit = async (): Promise<void> => {
    if (selectedFieldsRef.current.length === 0 && selectedAttachmentsRef.current.length === 0) {
      toast({
        title: 'Error',
        description: 'Please select at least one field or attachment to request',
        status: 'error',
        duration: 5000,
        isClosable: true
      });
      return;
    }

    if ([null, ''].includes(profile?.email ?? '') && additionalRecipients.length === 0) {
      toast({
        title: 'Error',
        description:
          'The profile does not have an email address to send the RFI to, please either add an email address or add additional recipients',
        status: 'error',
        duration: 5000,
        isClosable: true
      });
      return;
    }

    const rfiResponse = await createRFI({
      orgId: activeOrgID as string,
      profileId: profileId,
      request: noteRef.current,
      requestedFields: selectedFieldsRef.current,
      requestedDocuments: selectedAttachmentsRef.current,
      additionalRecipients
    });
    if ('data' in rfiResponse) {
      await updateProfileStatus({
        profileId,
        status: PROFILE_STATUS_CODE.RFI_SENT
      }).unwrap();
      toast({
        title: 'Success',
        description: 'RFI created successfully',
        status: 'success'
      });
      onClose();
    } else {
      toast({
        title: 'Error',
        description: 'Error creating RFI',
        status: 'error'
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="5xl" scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent maxHeight="90vh">
        <ModalHeader>Request for Information</ModalHeader>
        <ModalCloseButton />
        <ModalBody overflowY="auto">
          <VStack spacing={6} align="stretch">
            <Alert status="info" borderRadius="md">
              <AlertIcon />
              <Box>
                <Text fontWeight="medium">This RFI will be sent to:</Text>
                <Text fontStyle="italic" mt={1}>
                  <EmailIcon mr={2} />
                  {profile?.email !== '' && profile?.email !== null ? (
                    <span>{profile?.email}</span>
                  ) : (
                    <Text as="span" color="orange.500">
                      No email address set for this profile
                    </Text>
                  )}
                  {additionalRecipients.length > 0 && (
                    <>
                      <Text as="span" mx={2}>
                        and
                      </Text>
                      {additionalRecipients.join(', ')}
                    </>
                  )}
                </Text>
              </Box>
            </Alert>
            <NoteToPointOfContactComponent note={noteRef.current} setNote={setNote} />
            <AdditionalRecipientsComponent
              additionalRecipients={additionalRecipients}
              setAdditionalRecipients={setAdditionalRecipients}
            />
            <RequiredAttachmentsComponent
              categorizedAttachmentTypes={categorizedAttachmentTypes}
              setSelectedAttachments={setSelectedAttachments}
            />
            <RequiredFieldsSelector profileFields={profileFields} setSelectedFields={setSelectedFields} />
          </VStack>
        </ModalBody>
        <ModalFooter display="flex" justifyContent="flex-end" gap={3}>
          <Button variant="ghost" border="1px" borderColor="gray.200" onClick={onClose}>
            Cancel
          </Button>
          <Button bg="black" color="white" mr={3} onClick={handleSubmit}>
            Send Request
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default RequestForInformationModal;
