import { Box, Heading, Grid, GridItem, Text, Table, Thead, Tbody, Tr, Th, Td, TableContainer } from '@chakra-ui/react';

const TriggersSection: React.FC<{ triggers: any[] }> = ({ triggers }) => {
  return (
    <Box outline="1px solid black" mt={4} pl={5} pr={5} pt={4} pb={4} bg="white">
      <Heading size="sm">Triggers</Heading>
      {triggers != null && triggers.length === 0 ? (
        <Grid templateColumns="auto" pt={4} pb={4}>
          <GridItem>
            <Text>No triggers found</Text>
          </GridItem>
        </Grid>
      ) : (
        <TableContainer pt={5}>
          <Table size="sm">
            <Thead>
              <Tr>
                <Th>Category</Th>
                <Th>% of Total Exposure</Th>
                <Th>Message</Th>
                <Th>Risk</Th>
                <Th>Min Threshold</Th>
                <Th>Max Threshold</Th>
              </Tr>
            </Thead>
            <Tbody>
              {triggers != null ? (
                triggers.map((trigger, idx) => (
                  <Tr key={idx} gridTemplateColumns="150px auto" pt={12}>
                    <Td>{trigger.category}</Td>
                    <Td>{trigger.percentage}</Td>
                    <Td>{trigger.message}</Td>
                    <Td>{trigger.ruleTriggered.risk}</Td>
                    <Td>{trigger.ruleTriggered.minThreshold}</Td>
                    <Td>{trigger.ruleTriggered.maxThreshold}</Td>
                  </Tr>
                ))
              ) : (
                <></>
              )}
            </Tbody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

export default TriggersSection;
