import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';

import AuthAPI from '@features/auth/api';
import { selectIsLoggedIn } from '@features/auth/authSlice';

const LogoutPage: React.FC = () => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(selectIsLoggedIn);

  useEffect(() => {
    if (isLoggedIn) {
      const authApi = new AuthAPI(dispatch);
      authApi.logout();
    }
  }, [isLoggedIn, dispatch]);

  return <Navigate to="/" />;
};

export default LogoutPage;
