import { Box, Button, Flex, Heading, Text, VStack, useToast } from '@chakra-ui/react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import MatchingScreeningHit from '@features/panels/alertsPanel/components/MatchingScreeningHit.component';
import { Loading } from '@features/shared/components';
import { selectActiveOrgID } from '@features/user-settings/userSlice';
import { PROFILE_TYPE, type IProfile } from '@models/profileTypes';
import {
  useGetProfileByIdQuery,
  useGetProfileInquiriesQuery,
  useUpdateProfileInquiryMutation
} from '@services/canaria.services';

import DisplayField from './DisplayField';

export const ScreenedDataContainer: React.FC = () => {
  const { profileId } = useParams();
  const orgId = useSelector(selectActiveOrgID);

  if (orgId == null) {
    throw new Error('activeOrgID is null');
  }

  if (profileId == null) {
    throw new Error('profileId is undefined');
  }

  const { data: profileData, isLoading: isProfileLoading } = useGetProfileByIdQuery({
    orgId,
    profileId
  });

  if (isProfileLoading || profileData == null) {
    return <Loading />;
  }

  return <ScreenedData profile={profileData} />;
};

const UNRESOLVED_STATUS = 'NO_RESOLUTION';

const ScreenedData: React.FC<{ profile: IProfile }> = ({ profile }) => {
  return (
    <VStack spacing={4} align="stretch" m={4} p={10} boxShadow="md" bg="white">
      <Heading as="h1" size="md" textAlign="left" mb={5}>
        Screened Data
      </Heading>
      <Box w="50%">
        <DisplayField label="Profile Name" value={profile.fullLegalName} />
        {profile.resourcetype === PROFILE_TYPE.INDIVIDUAL ? (
          <>
            <DisplayField label="Gender" value={profile.gender?.name} />
            <DisplayField label="Date of Birth" value={profile.dateOfBirth} />
            <DisplayField label="Citizenship" value={profile.citizenship?.englishName} />
            <DisplayField label="Place of Birth" value={profile.placeOfBirth?.englishName} />
            <DisplayField label="Id Number" value={profile.idNumber} />
            <DisplayField label="Id Type" value={profile.idType?.name} />
            <DisplayField label="Id Issuer" value={profile.idIssuer?.englishName} />
          </>
        ) : (
          <>
            <DisplayField label="DBA" value={profile.dbaTradeName} />
            <DisplayField label="Country of Incorporation" value={profile.placeOfEstablishment?.englishName} />
            <DisplayField label="Date of Incorporation" value={profile.dateOfEstablishment} />
            <DisplayField label="Business Address" value={profile.registeredBusinessAddress} />
            <DisplayField label="Physical Address" value={profile.physicalAddress} />
            <DisplayField label="Mailing Address" value={profile.mailingAddress} />
          </>
        )}
      </Box>
    </VStack>
  );
};

const InquiriesList: React.FC<{
  profileId: string;
  title: string;
  inquiries: any[];
}> = ({ profileId, title, inquiries }) => {
  const activeOrgID = useSelector(selectActiveOrgID);
  if (activeOrgID == null) {
    throw new Error('activeOrgID is null');
  }

  const toast = useToast();

  const [activeEntityId, setActiveEntityId] = useState<number | null>(null);
  const [updateProfileInquiry] = useUpdateProfileInquiryMutation();

  const onResolveHit = async (
    inquiryId: string,
    resolution: 'TRUE_MATCH' | 'FALSE_POSITIVE',
    notes: string
  ): Promise<void> => {
    try {
      await updateProfileInquiry({
        orgId: activeOrgID,
        profileId,
        inquiryId,
        resolution,
        notes
      }).unwrap();
      toast({
        status: 'success',
        title: 'Success',
        description: `Match resolved as ${resolution === 'TRUE_MATCH' ? 'True Match' : 'False Positive'}`,
        isClosable: true
      });
    } catch (err) {
      toast({
        status: 'error',
        title: 'Error',
        description: 'Failed to resolve match',
        isClosable: true
      });
    }
  };

  return (
    <VStack spacing={4} align="stretch" m={4} p={10} boxShadow="md" bg="white">
      <Heading as="h1" size="md" textAlign="left" mb={5}>
        {title}
      </Heading>
      <Flex justifyContent="space-between" p={2} borderBottom="1px" borderColor="gray.200">
        <Text flex={1} textAlign="start">
          Name
        </Text>
        <Text flex={1} textAlign="start">
          Watchlist(s)
        </Text>
        <Text flex={1} textAlign="start">
          Category
        </Text>
        <Text flex={1} textAlign="start">
          Score
        </Text>
        <Text flex={1} textAlign="start"></Text>
      </Flex>
      {inquiries.map((inquiry) => (
        <MatchingScreeningHit
          key={inquiry.id}
          screeningHit={inquiry}
          onResolve={onResolveHit as any}
          isExpanded={activeEntityId === inquiry.id}
          onToggle={() => {
            setActiveEntityId(activeEntityId === inquiry.id ? null : inquiry.id);
          }}
        />
      ))}
    </VStack>
  );
};

const WatchlistScreening: React.FC = () => {
  const navigate = useNavigate();
  const { profileId } = useParams();
  const orgId = useSelector(selectActiveOrgID);

  if (orgId == null) {
    throw new Error('activeOrgID is null');
  }

  if (profileId == null) {
    throw new Error('profileId is undefined');
  }

  const { data: inquiriesData, isLoading: isInquieriesLoading } = useGetProfileInquiriesQuery({
    orgId,
    profileId
  });

  const inquiries = inquiriesData?.results ?? [];

  if (isInquieriesLoading) {
    return <Loading message="Loading profile inquiries..." />;
  }

  return (
    <>
      <Heading as="h1" size="lg" textAlign="center" mb={5}>
        Watchlist Screening
      </Heading>
      <Flex justifyContent="end" mr={4}>
        <Button
          bg="black"
          color="white"
          onClick={() => {
            navigate(-1);
          }}
        >
          Return
        </Button>
      </Flex>
      <ScreenedDataContainer />

      <InquiriesList
        profileId={profileId}
        title="New Hits Needing Attention"
        inquiries={inquiries?.filter((inquiry) => inquiry.resolution === UNRESOLVED_STATUS)}
      />

      <InquiriesList
        profileId={profileId}
        title="Previously Resolved Hits"
        inquiries={inquiries?.filter((inquiry) => inquiry.resolution !== UNRESOLVED_STATUS)}
      />
    </>
  );
};

export default WatchlistScreening;
