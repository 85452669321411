interface IOption {
  label: string;
  value: any;
}

export const REGULATORY_INVESTIGATION_STATUS_OPTIONS: IOption[] = [
  { label: 'Under Investigation', value: 'UNDER_INVESTIGATION' },
  { label: 'No regulatory action', value: 'NO_REGULATORY_ACTION' }
];

export const LICENSING_REGISTRATION_STATUS_OPTIONS: IOption[] = [
  { label: 'In Progress', value: 'IN_PROGRESS' },
  { label: 'Active', value: 'ACTIVE' },
  { label: 'Inactive', value: 'INACTIVE' },
  { label: 'Not Registered', value: 'NOT_REGISTERED' }
];

export const AML_SANCTIONS_PROGRAM_REQUIRED_OPTIONS: IOption[] = [
  { label: 'Yes', value: true },
  { label: 'No', value: false }
];

export const AML_SANCTIONS_PROGRAM_ELEMENTS_OPTIONS: IOption[] = [
  { label: 'Policies and Procedures', value: 'PPS' },
  { label: 'Customer Onboarding, KYC & CIP', value: 'KYC' },
  { label: 'UBOs Identification', value: 'UBO' },
  { label: 'OFAC Sanctions Prohibitions', value: 'PSC' },
  { label: 'Customer Onboarding Screening', value: 'SSP' },
  { label: 'Customer Ongoing Screening', value: 'SOS' },
  { label: 'Transaction Screening', value: 'SST' },
  { label: 'AML Officer', value: 'CCO' },
  { label: 'Customer Onboarding', value: 'COB' },
  { label: 'Independent Testing', value: 'IND' },
  { label: 'Internal Controls', value: 'ICO' },
  { label: 'Senior Management Commitment', value: 'SMC' },
  { label: 'Risk Assessment', value: 'RAS' },
  { label: 'Customer Due Diligence', value: 'CDD' },
  { label: 'Monitoring', value: 'MTR' },
  { label: 'Reporting', value: 'SRP' },
  { label: 'Training', value: 'TRN' },
  { label: 'Audit', value: 'SAA' }
];
