export interface Trigger {
  category: string;
  percentage: number;
  message: string;
  ruleTriggered: {
    risk: string;
    minThreshold: number;
    maxThreshold: number;
  };
}

export const getTriggerDifferences = (
  previousTriggers: Trigger[],
  currentTriggers: Trigger[]
): {
  changedTriggers: Trigger[];
  remainingTriggers: Trigger[];
} => {
  if (currentTriggers?.length === 0) {
    return { changedTriggers: [], remainingTriggers: [] };
  }

  const changedTriggers: Trigger[] = [];
  const remainingTriggers: Trigger[] = [];

  currentTriggers?.forEach((currentTrigger: any) => {
    const previousTrigger = previousTriggers.find(
      (previousTrigger) => previousTrigger.category === currentTrigger.category
    );
    if (previousTrigger == null || previousTrigger.ruleTriggered.risk !== currentTrigger.ruleTriggered.risk) {
      changedTriggers.push(currentTrigger);
    } else {
      remainingTriggers.push(currentTrigger);
    }
  });
  return { changedTriggers, remainingTriggers };
};
