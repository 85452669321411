import { Box, Divider, Flex, Grid, GridItem, Heading, Tag, Text } from '@chakra-ui/react';

const OverviewSection: React.FC<{
  address: string;
  risk: string;
  cluster: {
    name: string;
    category: string;
  };
}> = ({ address, risk, cluster }) => {
  return (
    <Box height="100%" outline="1px solid black" p={5} bg="white">
      {/* Overview Box */}
      <Heading size="sm">Overview</Heading>
      <Flex pt={3} columnGap="8" flexWrap="wrap" pb={2}>
        <Text as="i" fontSize="md">
          Address
        </Text>
        <Text fontSize="md">{address}</Text>
      </Flex>
      <Grid templateColumns="auto auto" pt={6} columnGap={5}>
        <GridItem>
          <Text as="i">Risk</Text>
          <Divider borderColor="gray.300" borderBottom="1px" />
          <Grid templateColumns="auto auto">
            <GridItem borderBottom="1px" borderColor="gray.300">
              <Text as="i">Risk Level</Text>
            </GridItem>
            <GridItem borderBottom="1px" borderColor="gray.300" py={1}>
              <Tag bg={`risk.${risk.toUpperCase()}`} color="white">
                {risk}
              </Tag>
            </GridItem>
            <GridItem borderBottom="1px" borderColor="gray.300">
              <Text as="i">Last Risk Change</Text>
            </GridItem>
            <GridItem borderBottom="1px" borderColor="gray.300">
              <Text pl="1ch">Unknown</Text>
            </GridItem>
          </Grid>
        </GridItem>
        <GridItem>
          <Text as="i">Cluster</Text>
          <Divider borderColor="gray.300" borderBottom="1px" />
          <Grid templateColumns="auto auto">
            <GridItem borderBottom="1px" borderColor="gray.300" py={1}>
              <Text as="i">Name</Text>
            </GridItem>
            <GridItem borderBottom="1px" borderColor="gray.300">
              <Text pl="1ch">{cluster?.name ?? 'None'}</Text>
            </GridItem>
            <GridItem borderBottom="1px" borderColor="gray.300">
              <Text as="i">Category</Text>
            </GridItem>
            <GridItem borderBottom="1px" borderColor="gray.300">
              <Text pl="1ch">{cluster?.category ?? 'None'}</Text>
            </GridItem>
          </Grid>
        </GridItem>
      </Grid>
    </Box>
  );
};

export default OverviewSection;
