import {
  Select,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
  useDisclosure,
  useToast
} from '@chakra-ui/react';
import { useState } from 'react';

import RequestForInformationModal from '@features/request-for-information/RequestForInformationModal.component';
import { PROFILE_STATUS_CODE, PROFILE_STATUS_DESCRIPTION } from '@models/profileTypes';
import { useGetProfileStatusQuery, useUpdateProfileStatusMutation } from '@services/canaria.services';

interface StatusSelectorProps {
  profileId: string;
  status: PROFILE_STATUS_CODE;
  hasOpenItems: boolean;
}

const StatusSelector: React.FC<StatusSelectorProps> = ({ profileId, status }) => {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedStatus, setSelectedStatus] = useState<PROFILE_STATUS_CODE | null>(null);
  const [updateProfileStatus] = useUpdateProfileStatusMutation();
  const { data: profileStatus } = useGetProfileStatusQuery({ profileId });

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    const newStatus = e.target.value as PROFILE_STATUS_CODE;

    setSelectedStatus(newStatus);
    onOpen();
  };

  const handleConfirmChange = async (): Promise<void> => {
    if (selectedStatus != null) {
      try {
        await updateProfileStatus({
          profileId,
          status: selectedStatus
        }).unwrap();
      } catch (error: any) {
        if (error.data?.status != null) {
          toast({
            title: 'Update status failed.',
            description: error.data.status.join(', '),
            status: 'error',
            duration: 5000,
            isClosable: true
          });
        } else {
          toast({
            title: 'Update status failed.',
            description: 'An error occurred while updating the status.',
            status: 'error',
            duration: 5000,
            isClosable: true
          });
        }
      }
    }
    onClose();
  };

  return (
    <>
      <Select
        size="sm"
        borderRadius="full"
        variant="solid"
        colorScheme="yellow"
        value={status}
        onChange={handleSelectChange}
        width={200}
      >
        {profileStatus != null &&
          Object.values(PROFILE_STATUS_CODE)
            .filter((value) => {
              return value === status || profileStatus.allowedTransitions.includes(value);
            })
            .map((status) => (
              <option value={status} key={status}>
                {PROFILE_STATUS_DESCRIPTION[status].charAt(0).toUpperCase() +
                  PROFILE_STATUS_DESCRIPTION[status].slice(1).toLowerCase()}
              </option>
            ))}
      </Select>
      <RequestForInformationModal
        isOpen={isOpen && selectedStatus === PROFILE_STATUS_CODE.RFI_SENT}
        onClose={onClose}
        profileId={profileId}
      />
      <Modal isOpen={isOpen && selectedStatus !== PROFILE_STATUS_CODE.RFI_SENT} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm Status Change</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>
              Are you sure you want to change the status from <strong>{PROFILE_STATUS_DESCRIPTION[status]}</strong> to{' '}
              <strong>{PROFILE_STATUS_DESCRIPTION[selectedStatus as PROFILE_STATUS_CODE]}</strong>?
            </Text>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="gray" variant="outline" onClick={onClose} mr={3}>
              Cancel
            </Button>
            <Button color="white" bg="gray.800" onClick={handleConfirmChange}>
              Confirm
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default StatusSelector;
