import { Grid, GridItem, Wrap, Text, Link, Input, Button, useToast } from '@chakra-ui/react';
import * as Sentry from '@sentry/browser';
import { useState } from 'react';
import { SlWallet } from 'react-icons/sl';

import { truncateWalletAddress } from '@features/wallets/utils';

const NameDisplay: React.FC<{
  name: string;
  address: string;
  walletId: string;
  orgId: string;
  updateWallet?: any;
  updateWalletIsLoading?: boolean;
}> = ({ name, address, walletId, orgId, updateWallet, updateWalletIsLoading }) => {
  const [nameValue, setNameValue] = useState(name);
  const [isEditingName, setIsEditingName] = useState(false);
  const toast = useToast();

  const handleNameChange = (event): void => {
    setNameValue(event.target.value);
  };

  return (
    <Grid outline="1px solid black" pl={5} pt={4} pb={4} mb={3} bg="white" templateColumns="12% auto">
      <GridItem>
        <SlWallet size="100%" />
      </GridItem>
      <GridItem pl={3} pr={3} textOverflow="ellipsis" overflow="hidden">
        <Wrap>
          {!isEditingName ? (
            <>
              <Text size="sm" mb={0} fontWeight="bold">
                {name}
              </Text>
              {typeof updateWallet === 'function' && (
                <Link
                  size="sm"
                  onClick={() => {
                    setNameValue(name);
                    setIsEditingName(true);
                  }}
                >
                  (edit)
                </Link>
              )}
            </>
          ) : (
            <>
              <Input size="sm" value={nameValue} onChange={handleNameChange}></Input>
              <Button
                size="xs"
                mt={3}
                mr={2}
                isDisabled={updateWalletIsLoading}
                bg="red"
                colorScheme="green"
                onClick={() => {
                  setIsEditingName(false);
                }}
              >
                Cancel
              </Button>
              <Button
                size="xs"
                mt={3}
                isLoading={updateWalletIsLoading}
                bg="green.400"
                colorScheme="green"
                onClick={async () => {
                  try {
                    await updateWallet({
                      orgId,
                      walletId,
                      name: nameValue
                    }).unwrap();
                    setIsEditingName(false);
                  } catch (err) {
                    toast({
                      status: 'error',
                      title: 'Error',
                      description: 'Oh no, there was an error!',
                      isClosable: true
                    });
                    Sentry.captureException(err);
                  }
                }}
              >
                Save
              </Button>
            </>
          )}
        </Wrap>
        <Text fontSize="sm" isTruncated>
          {truncateWalletAddress(address)}
        </Text>
      </GridItem>
    </Grid>
  );
};

export default NameDisplay;
