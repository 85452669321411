function truncateWalletAddress(str): string {
  const num = 14;
  if (str.length <= num) {
    return str;
  }
  return str.slice(0, 6) + '...' + str.slice(str.length - 5, str.length);
}

function cadenceToFriendlyDisplay(val): string {
  if (val === 'N') return 'None';
  else if (val === 'D') return 'Daily';
  else if (val === 'W') return 'Weekly';
  else if (val === 'M') return 'Monthly';
  else if (val === 'Q') return 'Quarterly';
  else if (val === 'Y') return 'Yearly';
  else throw new Error('Unexpected cadence value');
}

export { cadenceToFriendlyDisplay, truncateWalletAddress };
