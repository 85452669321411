import { Box, Heading, Button, useToast, Flex, Link } from '@chakra-ui/react';
import * as Sentry from '@sentry/browser';
import { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const NotesDisplay: React.FC<{
  notes: string;
  walletId: string;
  orgId: string;
  updateWallet?: any;
  updateWalletIsLoading?: boolean;
}> = ({ notes, walletId, orgId, updateWallet, updateWalletIsLoading }) => {
  const [quillValue, setQuillValue] = useState(notes);
  const [isEditingNotes, setIsEditingNotes] = useState(false);
  const toast = useToast();

  useEffect(() => {
    setQuillValue(notes);
  }, [notes]);

  return (
    <Box outline="1px solid black" mt={4} pl={5} pr={5} pt={4} pb={4} bg="white">
      <Flex alignItems="center" mb={2}>
        <Heading size="sm">Notes</Heading>
        {!isEditingNotes && typeof updateWallet === 'function' && (
          <Link
            ml={2}
            onClick={() => {
              setQuillValue(notes);
              setIsEditingNotes(true);
            }}
          >
            (edit)
          </Link>
        )}
      </Flex>
      {!isEditingNotes ? (
        <ReactQuill
          key={`read-${isEditingNotes}`}
          theme="snow"
          readOnly={true}
          modules={{ toolbar: false }}
          value={notes}
        />
      ) : (
        <>
          <ReactQuill key={`edit-${isEditingNotes}`} theme="snow" value={quillValue} onChange={setQuillValue} />
          <Button
            mt={3}
            size="xs"
            mr={2}
            isDisabled={updateWalletIsLoading}
            bg="red"
            colorScheme="green"
            onClick={() => {
              setIsEditingNotes(false);
            }}
          >
            Cancel
          </Button>
          <Button
            mt={3}
            size="xs"
            isLoading={updateWalletIsLoading}
            bg="green.400"
            colorScheme="green"
            onClick={async () => {
              try {
                await updateWallet({
                  orgId,
                  walletId,
                  notes: quillValue
                }).unwrap();
                setIsEditingNotes(false);
              } catch (err) {
                toast({
                  status: 'error',
                  title: 'Error',
                  description: 'Oh no, there was an error!',
                  isClosable: true
                });
                Sentry.captureException(err);
              }
            }}
          >
            Save
          </Button>
        </>
      )}
    </Box>
  );
};

export default NotesDisplay;
