import { CheckIcon } from '@chakra-ui/icons';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  VStack,
  Flex,
  FormControl,
  Input,
  Select,
  Textarea,
  Text,
  FormLabel,
  Tooltip,
  IconButton,
  useToast
} from '@chakra-ui/react';
import { useState } from 'react';
import { Form, Field } from 'react-final-form';

import {
  useRegisterWalletMutation,
  useGetBlockchainNetworksQuery,
  useValidateAddressMutation
} from '@services/canaria.services';

interface WalletModalProps {
  isOpen: boolean;
  onClose: () => void;
  orgId: string;
  profileId?: string;
}

const WalletModal: React.FC<WalletModalProps> = ({ isOpen, onClose, orgId, profileId }) => {
  const toast = useToast();

  const [registerWallet, { isLoading }] = useRegisterWalletMutation();
  const { data, isLoading: isBlockchainNetworksLoading } = useGetBlockchainNetworksQuery({});
  const [validateAddress, { isLoading: isValidationLoading }] = useValidateAddressMutation();

  const [validationMessage, setValidationMessage] = useState<string>('');
  const [messageColor, setMessageColor] = useState('gray');
  const [canAddWallet, setCanAddWallet] = useState<boolean>(false);

  const handleClose = (): void => {
    setValidationMessage('');
    setMessageColor('gray');
    onClose();
  };

  const handleValidateAddress = async (values: any): Promise<void> => {
    try {
      if (values.blockchain == null || values.address == null) {
        toast({
          title: 'Error',
          description: 'Address and blockchain are required',
          status: 'error',
          duration: 9000,
          isClosable: true
        });
      }
      const result = await validateAddress({
        orgId,
        address: values.address,
        blockchain: values.blockchain
      }).unwrap();

      if (!result.canBeValidated) {
        setValidationMessage(
          'We currently do not support validation for this blockchain. If the address is correct feel free to ignore this message.'
        );
        setMessageColor('orange');
        setCanAddWallet(true);
      } else if (result.isValid) {
        let message = `The format of the address is OK.`;
        if (result.validatedAddress !== values.address) {
          message += `\nNew vs Old address:\n${values.address}\n${result.validatedAddress}`;
          message += `\nThis new address is a checksumed version of the old one.`;
        }
        if (values.blockchain === 'ENS') {
          message = `You provided an ENS address that currently translates to ${result.validatedAddress}.`;
        }
        setValidationMessage(message);
        setMessageColor('green');
        setCanAddWallet(true);
      } else {
        let message = 'The address you entered is not valid.';
        if (values.blockchain === 'ENS') {
          message = 'Your current ENS domain does not translate to an actual address.';
        } else if (result.isChecksumed) {
          message += ` Your address have lower and upper case letters. It was validated as a checksum address and it failed.`;
        }
        setValidationMessage(message);
        setMessageColor('red');
        setCanAddWallet(false);
      }
    } catch (err) {
      toast({
        title: 'Error',
        description: 'An internal error has occurred',
        status: 'error',
        isClosable: true
      });
      setCanAddWallet(false);
      throw err;
    }
  };

  const handleSubmit = async (values: any): Promise<void> => {
    try {
      const response = await registerWallet({ ...values, orgId, profile: profileId });
      if ('error' in response) {
        toast({
          title: 'Error',
          description: response.error.data?.detail,
          status: 'error',
          isClosable: true
        });
        return;
      }
      handleClose();
    } catch (error) {
      console.error(error);
    }
  };

  const handleFieldChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>): void => {
    if (event.target.name === 'blockchain' || event.target.name === 'address') {
      setValidationMessage('');
      setMessageColor('gray');
      setCanAddWallet(false);
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={handleClose} size="3xl">
        <ModalOverlay />
        <Form
          onSubmit={handleSubmit}
          subscription={{
            values: true
          }}
          render={({ handleSubmit, values }) => {
            const canValidate = values.blockchain != null && values.address != null;
            const addWalletButtonEnabled =
              canAddWallet && values.name != null && values.blockchain != null && values.address != null;

            const layer1 = data?.filter((network) => network.layer === 'L1') ?? [];
            const layer2 = data?.filter((network) => network.layer === 'L2') ?? [];
            const other = data?.filter((network) => network.layer === 'Other') ?? [];

            return (
              <form onSubmit={handleSubmit}>
                <ModalContent>
                  <ModalHeader>Add Wallet</ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>
                    <VStack spacing={4} align="stretch" p={5} w="100%">
                      <Flex gap={4}>
                        <FormControl isRequired>
                          <FormLabel htmlFor="name">Name</FormLabel>
                          <Field
                            name="name"
                            render={({ input }) => (
                              <>
                                <Input {...input} id="name" placeholder="Enter a wallet name" bg="white" />
                              </>
                            )}
                          />
                        </FormControl>
                      </Flex>
                      <Flex gap={4} align="end">
                        <FormControl id="blockchain" isRequired w="25%" isDisabled={isBlockchainNetworksLoading}>
                          <FormLabel>Blockchain</FormLabel>
                          <Field
                            name="blockchain"
                            render={({ input }) => (
                              <Select
                                {...input}
                                onChange={(event) => {
                                  input.onChange(event);
                                  handleFieldChange(event);
                                }}
                              >
                                <option value="">Select a blockchain</option>
                                {layer1.length > 0 && (
                                  <optgroup label="Layer 1">
                                    {layer1.map((network) => (
                                      <option key={network.value} value={network.value}>
                                        {network.name}
                                      </option>
                                    ))}
                                  </optgroup>
                                )}
                                {layer2.length > 0 && (
                                  <optgroup label="Layer 2">
                                    {layer2.map((network) => (
                                      <option key={network.value} value={network.value}>
                                        {network.name}
                                      </option>
                                    ))}
                                  </optgroup>
                                )}
                                <optgroup label="Other">
                                  {other.map((network) => (
                                    <option key={network.value} value={network.value}>
                                      {network.name}
                                    </option>
                                  ))}
                                </optgroup>
                              </Select>
                            )}
                          />
                        </FormControl>
                        <FormControl id="address" isRequired w="70%">
                          <FormLabel>Address</FormLabel>
                          <Field
                            name="address"
                            validate={(value) => {}}
                            render={({ input, meta }) => (
                              <>
                                <Input
                                  {...input}
                                  placeholder="Enter the address"
                                  onChange={(event) => {
                                    input.onChange(event);
                                    handleFieldChange(event);
                                  }}
                                  onKeyDown={(event) => {
                                    if (event.key === 'Enter' && canValidate) {
                                      event.preventDefault();
                                      void handleValidateAddress(values);
                                    }
                                  }}
                                />
                              </>
                            )}
                          />
                        </FormControl>
                        <Tooltip label={canValidate ? 'Validate' : 'Fill in all fields to enable validation'}>
                          <div>
                            <IconButton
                              aria-label="Validate address"
                              icon={<CheckIcon />}
                              isDisabled={!canValidate}
                              onClick={() => {
                                void handleValidateAddress(values);
                              }}
                              ml={2}
                              colorScheme={canValidate ? 'blue' : 'gray'}
                              isLoading={isValidationLoading}
                            />
                          </div>
                        </Tooltip>
                      </Flex>
                      <Flex>
                        {validationMessage != null && (
                          <Text color={messageColor} mt={2} fontSize="sm" as="pre" style={{ whiteSpace: 'pre-wrap' }}>
                            {validationMessage}
                          </Text>
                        )}
                      </Flex>

                      <FormControl id="notes">
                        <FormLabel>Notes</FormLabel>
                        <Field
                          name="notes"
                          render={({ input }) => <Textarea {...input} placeholder="Enter any notes" />}
                        />
                      </FormControl>
                    </VStack>
                  </ModalBody>

                  <ModalFooter>
                    <Button variant="ghost" mr={3} onClick={handleClose}>
                      Close
                    </Button>
                    <Button colorScheme="blue" type="submit" isLoading={isLoading} isDisabled={!addWalletButtonEnabled}>
                      Add wallet
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </form>
            );
          }}
        />
      </Modal>
    </>
  );
};

export default WalletModal;
