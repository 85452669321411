import { Box, Button, Flex, Heading, VStack } from '@chakra-ui/react';
import { Form } from 'react-final-form';
import { useNavigate } from 'react-router-dom';

import { useProfileWizard } from '@context/ProfileWizardContext';
import useQueryParams from '@hooks/useQueryParams';
import { useUpdateProfileMutation } from '@services/canaria.services';

import { EntityPrimaryPartyForm } from '../forms/primaryParty';

interface ProfileFormProps {
  orgId: string;
  profile: any;
}

const hundredYearsAgo = new Date();
hundredYearsAgo.setFullYear(hundredYearsAgo.getFullYear() - 100);

const AddPrimaryPartyForm: React.FC<ProfileFormProps> = ({ orgId, profile }) => {
  const navigate = useNavigate();
  const { currentStep, maxSteps } = useProfileWizard();
  const { queryParams, setQueryParams } = useQueryParams();

  const [updateProfile, { isLoading }] = useUpdateProfileMutation();

  const onSubmit = async (values): Promise<void> => {
    await updateProfile({
      orgId,
      profileId: profile.id,
      fullLegalName: values.fullLegalName,
      dbaTradeName: values.dbaTradeName,
      entityFormationType: values.entityFormationType,
      placeOfEstablishment: values.placeOfEstablishment,
      dateOfEstablishment: values.dateOfEstablishment,
      uniqueIdentificationNumber: values.uniqueIdentificationNumber,
      registeredBusinessAddress: values.registeredBusinessAddress,
      mailingAddress: values.mailingAddress,
      physicalAddress: values.physicalAddress,
      companyWebsite: values.companyWebsite
    });

    if (currentStep === maxSteps) {
      navigate(`/dashboard/profiles/${profile.id}`);
      return;
    }

    setQueryParams({ ...queryParams, step: currentStep + 1 });
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{
        ...profile,
        placeOfEstablishment: profile.placeOfEstablishment?.id
      }}
      render={({ handleSubmit }) => (
        <Box as="form" onSubmit={handleSubmit} w="90%">
          <Heading as="h1" size="lg" textAlign="center">
            2. Primary Party
          </Heading>
          <VStack spacing={4} align="stretch" p={5} boxShadow="md" bg="white" mt={4}>
            <EntityPrimaryPartyForm />
            <Flex justifyContent="center" gap={4}>
              <Button
                bg="gray.300"
                onClick={() => {
                  setQueryParams({ ...queryParams, step: currentStep - 1 });
                }}
              >
                Back
              </Button>
              <Button type="submit" bg="black" color="white" isLoading={isLoading}>
                {currentStep === maxSteps ? 'Finish' : 'Next'}
              </Button>
            </Flex>
          </VStack>
        </Box>
      )}
    />
  );
};

export default AddPrimaryPartyForm;
