import { CheckIcon, CloseIcon, AddIcon } from '@chakra-ui/icons';
import { Box, Flex, IconButton, Tag, TagLabel, TagCloseButton, FormControl, FormLabel } from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import { useState } from 'react';

import { type IProfile } from '@models/profileTypes';

const MultipleChoiceField: React.FC<{
  label: string;
  placeholder: string;
  profile: IProfile;
  options: Array<{ value: number | string; label: string }>;
  handleFieldChange: (name: string) => (value: string | number | Date | number[]) => Promise<void>;
  fieldLabel: string;
  labelMapping?: boolean;
}> = ({ label, placeholder, profile, options, handleFieldChange, fieldLabel, labelMapping }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [newValue, setNewValue] = useState<{ value: number | string; label: string } | null>(null);
  const [isRemoving, setIsRemoving] = useState(false);

  const handleAddValue = async (): Promise<void> => {
    if (newValue == null) {
      return;
    }
    const currentValues = profile[fieldLabel] ?? [];
    const updatedValues = Array.isArray(currentValues)
      ? [...currentValues.map((c) => (typeof c === 'object' ? c.id : c)), newValue.value]
      : [newValue.value];
    await handleFieldChange(fieldLabel)([...new Set(updatedValues)]);
    setNewValue(null);
    setIsEditing(false);
  };

  const handleRemoveValue = async (valueId: number | string): Promise<void> => {
    setIsRemoving(true);
    const updatedValues = (profile[fieldLabel] ?? [])
      .filter((c) => {
        const isObject = typeof c === 'object';
        return isObject ? c.id !== valueId : c !== valueId;
      })
      .map((c) => (typeof c === 'object' ? c.id : c));
    await handleFieldChange(fieldLabel)([...new Set(updatedValues)] as any[]);
    setIsRemoving(false);
  };

  return (
    <Box display="flex" flexDirection="column" alignSelf="flex-start" width="100%">
      <FormControl display="flex" alignItems="center" my={0} flexDirection="row" flexGrow={1}>
        <FormLabel fontSize="sm" width="200px" flexShrink={0}>
          {label}
        </FormLabel>
        <Flex flexGrow={1} justifyContent="flex-start" alignItems="center">
          {!isEditing && (
            <IconButton
              size="sm"
              icon={<AddIcon />}
              aria-label={`Add ${label}`}
              onClick={() => {
                setIsEditing(true);
              }}
            />
          )}

          {isEditing && (
            <Flex alignItems="center">
              <IconButton
                ml={2}
                size="sm"
                icon={<CheckIcon />}
                aria-label={`Confirm add ${label}`}
                onClick={() => {
                  if (isRemoving) {
                    return;
                  }
                  void handleAddValue();
                }}
                isDisabled={newValue == null || isRemoving}
              />
              <IconButton
                ml={2}
                size="sm"
                icon={<CloseIcon />}
                aria-label={`Cancel add ${label}`}
                onClick={() => {
                  setIsEditing(false);
                  setNewValue(null);
                }}
              />
            </Flex>
          )}
        </Flex>
      </FormControl>
      {isEditing && (
        <Box flexGrow={1} mt={2}>
          <Select
            options={options.filter((option) => {
              return !((profile[fieldLabel] as Array<string | { id: number }>) ?? []).some((c) => {
                return typeof c === 'object' ? c.id === option.value : c === option.value;
              });
            })}
            value={newValue}
            onChange={(selected: any) => {
              setNewValue(selected);
            }}
            placeholder={placeholder}
          />
        </Box>
      )}
      <Flex flexWrap="wrap" mt={2} opacity={isRemoving ? 0.4 : 1}>
        {profile[fieldLabel]?.map((value) => {
          const id = typeof value === 'object' ? value.id : value;
          const displayName =
            typeof value === 'object'
              ? value.englishName
              : labelMapping === true
                ? options.find((option) => option.value === value)?.label
                : value;
          return (
            <Tag key={id} size="md" borderRadius="full" variant="solid" colorScheme="blue" mr={2} mb={2}>
              <TagLabel>{displayName}</TagLabel>
              <TagCloseButton
                isDisabled={isRemoving}
                cursor={isRemoving ? 'not-allowed' : 'pointer'}
                onClick={() => {
                  if (isRemoving) {
                    return;
                  }
                  void handleRemoveValue(id);
                }}
              />
            </Tag>
          );
        })}
      </Flex>
    </Box>
  );
};

export default MultipleChoiceField;
