import { InfoIcon } from '@chakra-ui/icons';
import {
  Box,
  Container,
  Flex,
  Grid,
  GridItem,
  Heading,
  Link,
  Spinner,
  Text,
  Tooltip as ChakraTooltip
} from '@chakra-ui/react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { useState, useEffect } from 'react';
import { SlExclamation } from 'react-icons/sl';
import { useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';

import { selectActiveOrgID } from '@features/user-settings/userSlice';
import AddressIdentificationSection from '@features/wallets/AddressIdentificationSection.component';
import ExposureData from '@features/wallets/ExposureData.component';
import NameDisplay from '@features/wallets/NameDisplay.component';
import NotesDisplay from '@features/wallets/NotesDisplay.component';
import OverviewSection from '@features/wallets/OverviewSection.component';
import ScreeningInfoDisplay from '@features/wallets/ScreeningInfoDisplay.component';
import TriggersSection from '@features/wallets/TriggersSection.component';
import {
  useGetWalletByIDQuery,
  useUpdateWalletMutation,
  useNewWalletInquiryMutation
} from '@services/canaria.services';
import { RISK_BG_COLORS, RISK_BORDER_COLORS } from '@utils/consts';
import { getRiskLevel } from '@utils/wallet';

ChartJS.register(ArcElement, Tooltip, Legend);

export const getExposureInformation = (
  exposures: Array<{ value: number; category: string }>
): {
  sum: number;
  exposureRisk: Map<string, string>;
  severeVal: string;
  highVal: string;
  mediumVal: string;
  lowVal: string;
} => {
  let sum = 0.0;
  let categorySevere = 0.0;
  let categoryHigh = 0.0;
  let categoryMedium = 0.0;
  let categoryLow = 0.0;

  // two passes. one to get total sum, the other one to calculate
  // severity based on thresholds
  sum = exposures.reduce((acc, exposure) => {
    return acc + exposure.value;
  }, 0);

  const exposureRisk = new Map();

  for (const exposure of exposures) {
    const exposurePercent = (exposure.value / sum) * 100.0;
    const riskLevel = getRiskLevel(exposure.category, exposurePercent);
    if (riskLevel === 'Severe') {
      categorySevere += exposure.value;
    } else if (riskLevel === 'High') {
      categoryHigh += exposure.value;
    } else if (riskLevel === 'Medium') {
      categoryMedium += exposure.value;
    } else if (riskLevel === 'Low') {
      categoryLow += exposure.value;
    }
    exposureRisk.set(exposure.category, riskLevel);
  }

  const calculatePercentage = (value): string => {
    return ((value / sum) * 100).toFixed(2);
  };

  const severeVal = calculatePercentage(categorySevere);
  const highVal = calculatePercentage(categoryHigh);
  const mediumVal = calculatePercentage(categoryMedium);
  const lowVal = calculatePercentage(categoryLow);

  return { sum, exposureRisk, severeVal, highVal, mediumVal, lowVal };
};

// https://docs.chainalysis.com/api/address-screening/#get-an-audit-trail-of-the-risk-of-screened-addresses

export const InquiryResult: React.FC<{
  data: any;
  error: any;
  isLoading: boolean;
  orgId: string;
  walletID: string;
  state: any;
  showExposure: boolean;
  updateWallet: any;
  updateWalletIsLoading: boolean;
  newWalletInquiry: any;
  newWalletInquiryLoading: boolean;
}> = ({
  data,
  error,
  isLoading,
  orgId,
  walletID,
  state,
  showExposure,
  updateWallet,
  updateWalletIsLoading,
  newWalletInquiry,
  newWalletInquiryLoading
}) => {
  if (isLoading) {
    return <Spinner />;
  }

  if (error != null) {
    return <Text>Error found loading data</Text>;
  }

  if (data == null) {
    return null;
  }

  const { sum, exposureRisk, severeVal, highVal, mediumVal, lowVal } = getExposureInformation(
    data.last_inquiry.result.exposures
  );

  const chartData = {
    labels: ['Severe', 'High', 'Medium', 'Low'],
    datasets: [
      {
        label: '% of Total Exposure',
        data: [severeVal, highVal, mediumVal, lowVal],
        backgroundColor: [RISK_BG_COLORS.SEVERE, RISK_BG_COLORS.HIGH, RISK_BG_COLORS.MEDIUM, RISK_BG_COLORS.LOW],
        borderColor: [
          RISK_BORDER_COLORS.SEVERE,
          RISK_BORDER_COLORS.HIGH,
          RISK_BORDER_COLORS.MEDIUM,
          RISK_BORDER_COLORS.LOW
        ],
        borderWidth: 0
      }
    ]
  };

  return (
    <Box mb={4}>
      {state?.profileId != null ? (
        <Link href={`/dashboard/profiles/${state.profileId}`}>← Back to profile</Link>
      ) : (
        <Link href="/dashboard/wallets/">← Back to Wallets</Link>
      )}
      <Flex flexDirection="row" pt={2} flexWrap="wrap" gap={3}>
        <Box flexGrow="1" flexBasis={180}>
          <NameDisplay
            name={data.name}
            address={data.address}
            walletId={walletID}
            orgId={orgId}
            updateWallet={updateWallet}
            updateWalletIsLoading={updateWalletIsLoading}
          />
          <ScreeningInfoDisplay
            createdAt={data.created_at}
            lastInquiryCreatedAt={data.last_inquiry.created_at}
            frequency={data.schedule.frequency}
            walletId={walletID}
            orgId={orgId}
            walletName={data.name}
            walletAddress={data.address}
            updateWallet={updateWallet}
            updateWalletIsLoading={updateWalletIsLoading}
            newWalletInquiry={newWalletInquiry}
            newWalletInquiryLoading={newWalletInquiryLoading}
          />
        </Box>
        <Box flexGrow="2">
          <OverviewSection
            address={data.address}
            risk={data?.last_inquiry?.result?.risk}
            cluster={data?.last_inquiry?.result?.cluster}
          />
        </Box>
      </Flex>
      <NotesDisplay
        notes={data.notes}
        walletId={walletID}
        orgId={orgId}
        updateWallet={updateWallet}
        updateWalletIsLoading={updateWalletIsLoading}
      />
      <AddressIdentificationSection addressIdentifications={data?.last_inquiry?.result?.addressIdentifications} />
      <TriggersSection triggers={data?.last_inquiry?.result?.triggers} />

      <Box outline="1px solid black" mt={4} pl={5} pr={5} pt={4} pb={4} mb={4} bg="white">
        <Heading size="sm">
          Exposure{' '}
          <ChakraTooltip label="Exposure refers to the relationship between the screened address and other entities that is created by transfers to/from the address. Exposure is determined by the last identified source of funds and the first identified destination of funds for all transfers that have been received to, or sent from, the screened address.">
            <InfoIcon />
          </ChakraTooltip>
        </Heading>
        {!showExposure ? (
          <Grid templateColumns="auto" justifyItems={'center'} pt={4} pb={4}>
            <GridItem pb={4}>
              <SlExclamation size={30} />
            </GridItem>
            <GridItem>
              <Text>
                If an address is nested at a VASP, or categorized as an exchange, merchant services, or hosted wallet,
                it will not trigger exposure risk.
              </Text>
            </GridItem>
          </Grid>
        ) : (
          <ExposureData
            exposures={[...data.last_inquiry.result.exposures]}
            sum={sum}
            exposureRisk={exposureRisk}
            chartData={chartData}
          />
        )}
      </Box>
    </Box>
  );
};

const Wallet: React.FC = () => {
  const { state } = useLocation();
  const { walletID } = useParams();
  const orgId = useSelector(selectActiveOrgID);
  if (orgId == null) {
    throw new Error('orgId is null');
  }
  const [showExposure, setShowExposure] = useState(false);
  if (walletID == null) {
    throw new Error('walletID is null');
  }
  const { data, error, isLoading } = useGetWalletByIDQuery({
    orgId,
    walletId: walletID
  });

  useEffect(() => {
    if (data != null) {
      setShowExposure(
        !['exchange', 'hosted wallet', 'merchant services'].includes(data.last_inquiry.result?.cluster?.category)
      );
    }
  }, [data]);

  const [updateWallet, { isLoading: updateWalletIsLoading }] = useUpdateWalletMutation();

  const [newWalletInquiry, { isLoading: newWalletInquiryLoading }] = useNewWalletInquiryMutation();

  return (
    <Container maxW="8xl">
      <InquiryResult
        data={data}
        error={error}
        isLoading={isLoading}
        orgId={orgId}
        walletID={walletID}
        state={state}
        showExposure={showExposure}
        updateWallet={updateWallet}
        updateWalletIsLoading={updateWalletIsLoading}
        newWalletInquiry={newWalletInquiry}
        newWalletInquiryLoading={newWalletInquiryLoading}
      />
    </Container>
  );
};

export default Wallet;
