import { CheckIcon, CloseIcon, EditIcon } from '@chakra-ui/icons';
import { Flex, IconButton, Select, FormControl, FormLabel, Text, Spacer } from '@chakra-ui/react';
import { useState } from 'react';

interface EditableSelectProps {
  label: string;
  value: string | number;
  options: Array<{ name: string; value: string | number }>;
  onConfirmChange: (value: string | number) => void;
  isDisabled?: boolean;
  allowNull?: boolean;
}

const EditableSelect: React.FC<EditableSelectProps> = ({
  label,
  value,
  options,
  onConfirmChange,
  isDisabled = false,
  allowNull = true
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editValue, setEditValue] = useState(value);

  const handleEdit = (): void => {
    setIsEditing(true);
  };

  const handleCancel = (): void => {
    setIsEditing(false);
    setEditValue(value);
  };

  const handleConfirm = (): void => {
    if (editValue !== value) {
      onConfirmChange(editValue);
    }
    setIsEditing(false);
  };

  return (
    <FormControl display="flex" alignItems="center">
      <FormLabel mb="0" width="175px" fontSize="sm">
        {label}
      </FormLabel>
      <Flex flex={1} align="center">
        {isEditing ? (
          <>
            <Select
              value={editValue}
              onChange={(e) => {
                setEditValue(e.target.value);
              }}
              placeholder={allowNull ? 'Select an option' : undefined}
              size="sm"
            >
              {options.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.name}
                </option>
              ))}
            </Select>
            <IconButton ml="1" size="sm" icon={<CheckIcon />} onClick={handleConfirm} aria-label="confirm" />
            <IconButton ml="1" size="sm" icon={<CloseIcon />} onClick={handleCancel} aria-label="cancel" />
          </>
        ) : (
          <>
            <Text fontSize="sm">{options.find((option) => option.value === value)?.name ?? '—'}</Text>
            <Spacer />
            {!isDisabled && <IconButton size="sm" icon={<EditIcon />} onClick={handleEdit} aria-label="edit" />}
          </>
        )}
      </Flex>
    </FormControl>
  );
};

export default EditableSelect;
