import { Box, Heading, Text, Button, useToast } from '@chakra-ui/react';
import dayjs from 'dayjs';

import CadenceDisplay from '@features/wallets/CadenceDisplay.component';

const ScreeningInfoDisplay: React.FC<{
  createdAt: string;
  lastInquiryCreatedAt: string;
  frequency: string;
  walletId: string;
  orgId: string;
  walletName: string;
  walletAddress: string;
  updateWallet?: any;
  updateWalletIsLoading?: boolean;
  newWalletInquiry?: any;
  newWalletInquiryLoading?: boolean;
}> = ({
  createdAt,
  lastInquiryCreatedAt,
  frequency,
  walletId,
  orgId,
  walletName,
  walletAddress,
  updateWallet,
  updateWalletIsLoading,
  newWalletInquiry,
  newWalletInquiryLoading
}) => {
  const toast = useToast();

  return (
    <Box outline="1px solid black" pl={5} pt={4} pb={4} bg="white">
      <Heading size="sm">Screening Information</Heading>
      <Text fontSize="xs" pt={3}>
        Date of Initial Screening
      </Text>
      <Text fontSize="md" pt={1}>
        {dayjs(createdAt).format('MMMM D, YYYY')}
      </Text>
      <Text fontSize="xs" pt={3}>
        Date of Most Recent Screening
      </Text>
      <Box display="flex">
        <Text fontSize="md" pt={1}>
          {dayjs(lastInquiryCreatedAt).format('MMMM D, YYYY')}
        </Text>
        {newWalletInquiry !== undefined && (
          <Button
            ml={4}
            mt={1}
            fontSize="sm"
            size="sm"
            colorScheme="blue"
            bg="blue.400"
            isLoading={newWalletInquiryLoading}
            onClick={async () => {
              try {
                await newWalletInquiry({
                  orgId,
                  walletId,
                  walletName,
                  walletAddress
                }).unwrap();
              } catch (err) {
                toast({
                  status: 'error',
                  title: 'Error',
                  description: 'Oh no, there was an error!',
                  isClosable: true
                });
              }
            }}
          >
            Rescreen
          </Button>
        )}
      </Box>
      <Text fontSize="xs" pt={3}>
        Automatic Screening Cadence
      </Text>
      <Box display="flex" pt={3}>
        <CadenceDisplay
          frequency={frequency}
          walletId={walletId}
          orgId={orgId}
          updateWallet={updateWallet}
          updateWalletIsLoading={updateWalletIsLoading}
        />
      </Box>
    </Box>
  );
};

export default ScreeningInfoDisplay;
