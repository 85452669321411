import { CATEGORY_NAMES } from './walletRisks.const';
export enum RISK_BG_COLORS {
  SEVERE = '#d72638C8',
  HIGH = '#f34213C8',
  MEDIUM = '#f5c800C8',
  LOW = '#00a676C8'
}

export enum RISK_BORDER_COLORS {
  SEVERE = '#d72638',
  HIGH = '#f34213',
  MEDIUM = '#f5c800',
  LOW = '#00a676'
}

export enum Risk {
  SEVERE = 'Severe',
  HIGH = 'High',
  MEDIUM = 'Medium',
  LOW = 'Low'
}

export const RiskRank = {
  Severe: 4,
  High: 3,
  Medium: 2,
  Low: 1
};

export const USA_STATES = [
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'Florida',
  'Georgia',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming'
];

export { CATEGORY_NAMES };
