import { Container, Box, Flex, Button, Tooltip as ChakraTooltip, VStack } from '@chakra-ui/react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import AlertBody from '@features/panels/alertsPanel/components/AlertBody.component';
import AlertHeader from '@features/panels/alertsPanel/components/AlertHeader.component';
import { AlertNotes } from '@features/panels/alertsPanel/components/AlertNotes.component';
import { ALERT_STATUS } from '@features/panels/alertsPanel/constants';
import { useAlertActions } from '@features/panels/alertsPanel/hooks/useAlertActions';
import { useProfileInquiryActions } from '@features/panels/alertsPanel/hooks/useProfileInquiryActions';
import { Loading } from '@features/shared/components';
import { selectActiveOrgID } from '@features/user-settings/userSlice';
import { useGetAlertByIDQuery } from '@services/canaria.services';

ChartJS.register(ArcElement, Tooltip, Legend);

const AlertDetail: React.FC = () => {
  const activeOrgID = useSelector(selectActiveOrgID);
  if (activeOrgID == null) {
    throw new Error('activeOrgID is null');
  }
  const { alertID } = useParams();
  if (alertID == null) {
    throw new Error('alertID must be provided');
  }
  const { data, /* isFetching, isLoading, */ refetch } = useGetAlertByIDQuery({
    orgId: activeOrgID,
    alertId: alertID
  });
  const [activeEntityId, setActiveEntityId] = useState<number | null>(null);
  const { handleAlertStatusUpdate, onReassign, updateAlertNotes, updateAlertIsLoading } = useAlertActions(
    Number(alertID),
    Number(activeOrgID),
    data?.notes,
    data?.status
  );

  const { onResolveMatch } = useProfileInquiryActions(activeOrgID, data, refetch, handleAlertStatusUpdate);

  const allMatchesResolved: boolean = useMemo(() => {
    return data?.profile_inquiries?.every((inquiry) => inquiry.resolution !== 'NO_RESOLUTION') ?? true;
  }, [data?.profile_inquiries]);

  const allMatchesFalsePositive: boolean = useMemo(() => {
    return data?.profile_inquiries?.every((inquiry) => inquiry.resolution === 'FALSE_POSITIVE') ?? true;
  }, [data?.profile_inquiries]);

  const canCloseAlert: boolean =
    allMatchesResolved && (allMatchesFalsePositive || data?.status === ALERT_STATUS.ESCALATED);

  const getTooltipMessage = (): string => {
    if (!allMatchesResolved) {
      return 'Please resolve every match before closing the alert';
    }
    if (!allMatchesFalsePositive && data?.status !== ALERT_STATUS.ESCALATED) {
      return 'This alert contains true matches. Please escalate the alert before closing.';
    }
    return '';
  };

  const handleSaveNotes = async (newNotes: string): Promise<void> => {
    await updateAlertNotes(newNotes);
  };

  if (data == null) {
    return <Loading message="Loading Alert details" />;
  }

  return (
    <Container maxW="8xl">
      <Box>
        <AlertHeader alert={data} onReassign={onReassign} />

        <AlertBody
          alert={data}
          onResolveMatch={onResolveMatch}
          activeEntityId={activeEntityId}
          setActiveEntityId={setActiveEntityId}
        />
        <Box p={5} bg="white" outline="1px solid black" mb={4}>
          <Flex>
            <Box flex="1" mr={4}>
              <AlertNotes initialNotes={data?.notes ?? ''} onSave={handleSaveNotes} isLoading={updateAlertIsLoading} />
            </Box>
            <Box width="200px">
              <VStack spacing={2} width="100%" height="100%" justifyContent="flex-end">
                {![ALERT_STATUS.CLOSED, ALERT_STATUS.ESCALATED].includes(data?.status) && (
                  <Button
                    fontSize="sm"
                    size="md"
                    bg="white"
                    color="black"
                    border="1px solid black"
                    isDisabled={updateAlertIsLoading}
                    onClick={async () => {
                      await handleAlertStatusUpdate(ALERT_STATUS.ESCALATED);
                    }}
                    width="100%"
                  >
                    Escalate Alert
                  </Button>
                )}
                <ChakraTooltip
                  label={getTooltipMessage()}
                  isDisabled={canCloseAlert || data?.status === ALERT_STATUS.CLOSED}
                  hasArrow
                  placement="bottom"
                  bg="gray.700"
                  color="white"
                >
                  <Box textAlign="center" width="100%">
                    <Button
                      fontSize="sm"
                      size="md"
                      bg={'black'}
                      color={'white'}
                      border={'1px solid black'}
                      isDisabled={updateAlertIsLoading || (data?.status !== ALERT_STATUS.CLOSED && !canCloseAlert)}
                      onClick={async () => {
                        await handleAlertStatusUpdate(
                          data?.status === ALERT_STATUS.CLOSED ? ALERT_STATUS.OPEN : ALERT_STATUS.CLOSED
                        );
                      }}
                      width="100%"
                    >
                      {data?.status === ALERT_STATUS.CLOSED ? 'Open Alert' : 'Close Alert'}
                    </Button>
                  </Box>
                </ChakraTooltip>
              </VStack>
            </Box>
          </Flex>
        </Box>
      </Box>
    </Container>
  );
};

export default AlertDetail;
